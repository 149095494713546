(function (win, $) {
    'use strict';

    var dk = window.dk,
        stickyDefaults = {
            ft: 75, // fixedTop
            cmt: 60, // containerMinTop
            cbmo: 86, // containerBottomMinOffset
            cbp: 15 // containerBottomPad
        },
        fixedTop = stickyDefaults.ft, // The top position when the panel is sticky (fixed)
        containerMinTop = stickyDefaults.cmt, // The minimum top offset for .dk-main-container before the panel becomes sticky
        containerBottomMinOffset = stickyDefaults.cbmo, // The minimum offset for the bottom of .dk-container-main (less the height of the panel box) before the panel reattaches
        containerBottomPad = stickyDefaults.cbp; // The distance from the bottom of .dk-container-main at which the panel will reattach

    function updateStickyVals() {
        function val(v) {
            return dk.responsive.isTablet ? dk.toTabletPx(v) : v;
        }
        fixedTop = val(stickyDefaults.ft);
        containerMinTop = val(stickyDefaults.cmt);
        containerBottomMinOffset = val(stickyDefaults.cbmo);
        containerBottomPad = val(stickyDefaults.cbp);
    }

    $(function () {
        $('.dk-subscribe-panel:not(:first)').remove();
        $('.dk-subscribe-panel:first').each(function () {
            var $panel = $(this),
                $tray = $('.tray', $panel),
                $links = $('.links a', $tray),
                $button = $panel.children(':first'),
                $ctr = $('.dk-main-container:first'),
                debounceUpdateSticky = dk.debounce(function () { updateStickyVals(); }, 250, true),
                toggleOverlay, keydownTo;

            function resetPanelLocation() {
                var panelBox = $panel[0].getBoundingClientRect(),
                    ctrBox = $ctr[0].getBoundingClientRect();
                if (ctrBox.top <= containerMinTop && (ctrBox.bottom >= containerBottomMinOffset + panelBox.height)) {
                    $panel.css({ 'position': 'fixed', 'top': fixedTop, 'left': panelBox.left, 'right': 'auto' });
                } else if (ctrBox.bottom < containerBottomMinOffset + panelBox.height) {
                    $panel.css({ 'position': '', 'top': ctrBox.height - panelBox.height - containerBottomPad, 'left': '', 'right': '' });
                } else {
                    $panel.css({ 'position': '', 'top': '', 'left': '', 'right': '' });
                }
            }

            toggleOverlay = function (show) {
                var op;
                if (show !== $panel.hasClass('opened')) {
                    op = show ? 'addClass' : 'removeClass';
                    $('.site-wrap')[op]('subscribe-overlay');
                    dk.setBodyScrollLock(show);
                    $panel[op]('opened');
                    if (show) {
                        $('.page-mask').on('click.closeSubscribe', function () {
                            toggleOverlay(false);
                        });
                    } else {
                        $('.page-mask').off('click.closeSubscribe');
                    }
                    $button.attr('title', ($button.attr('title') || '').replace('Hide', 'Show'));
                }
            };

            $button.on('click', function (e) {
                e.preventDefault();
                toggleOverlay(!$panel.hasClass('opened'));
                return false;
            });

            $(document).on('keydown.subscribe', function (e) {
                var w = e.which;
                if (w === 9) {
                    clearTimeout(keydownTo);
                    keydownTo = setTimeout(function () {
                        toggleOverlay($links.filter(document.activeElement).length > 0);
                    }, 50);
                }
            });

            $(win).on('dk:widthChanged', function (_, { isMobile }) {
                debounceUpdateSticky();
                $panel.css({ 'position': '', 'top': '', 'left': '', 'right': '' });
                if (!isMobile) {
                    resetPanelLocation();
                    $(win).on('scroll.subscribe', function () {
                        resetPanelLocation();
                    });
                } else {
                    $(win).off('scroll.subscribe');
                }
            });

            debounceUpdateSticky();
        });
    });
})(window, window.jQuery);
