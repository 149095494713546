// This file contains properties and functions exposed under the window.dk namespace for use across other JavaScript files
(function ($, win) {
    'use strict';

    var breakpoints =
        {
            mobile: {
                max: 649
            },
            tablet: {
                min: 650,
                max: 1024
            },
            desktop: {
                min: 1025,
                max: 1439
            },
            xl: {
                min: 1440
            }
        },
        stickyNavOffset = 84,
        rootElt = document.querySelector('html'),
        rootClasses = rootElt ? rootElt.getAttribute('class') : '',
        rootHasAuthoringClass = rootClasses.match(/(^|\s)experienceeditor(\s|$)/ig) ? true : false,
        rootHasEditingClass = rootClasses.match(/(^|\s)editmode(\s|$)/ig) ? true : false,
        isSitecoreScriptAuthoring = (win.Sitecore && win.Sitecore.PageModes) ? true : false,
        isSitecoreScriptEditing = (isSitecoreScriptAuthoring && win.Sitecore.PageModes.PageEditor) ? true : false,
        $htmlElt = $(rootElt),
        generatedIds = [];

    function firstOfType(arr, type) {
        var ret;
        if (Array.isArray(arr)) {
            arr.forEach(function (v) {
                ret = (ret === undefined && typeof v === type) ? v : ret;
            });
        }
        return ret;
    }

    function deduplicateIdentifier(id) {
        var sel, i = 0, res = id;
        do {
            if (i++) res = id + '-' + i; // no suffix if i === 0; suffix starting at '-2' if i > 0
            sel = '[id="' + res + '"],a[name="' + res + '"]';
        } while (~generatedIds.indexOf(res) || document.querySelectorAll(sel).length); // while generatedIds includes res, or res exists in DOM
        generatedIds.push(res);
        return res;
    }

    function getIdentifierFromText(elt, maxWords) {
        var txt,
            omitWords = ['a', 'and', 'of', 'the'],
            omitReg = new RegExp('\\b(?:' + omitWords.join('|') + ')\\b', 'ig');
        maxWords = maxWords > -1 ? maxWords : 6;

        if (typeof elt === 'object') {
            if (elt.jquery) elt = elt.get(0);
            if (elt.innerText) txt = elt.innerText;
        } else if (typeof elt === 'string') {
            txt = elt;
        }

        txt = (txt || '').toLowerCase().replace(/[^a-z0-9-]/ig, '-').replace(omitReg, '').replace(/-{2,}/g, '-').replace(/^-+|-+$/g, '');
        if (maxWords > 0) {
            txt = txt.split('-').slice(0, maxWords).join('-');
        }
        return deduplicateIdentifier(txt);
    }

    function generateId(prefix, elt, setEltId) {
        var id;
        function gen() {
            prefix = typeof prefix === 'string' ? prefix : 'id-';
            return prefix + Math.floor(Math.random() * 100000).toString(26);
        }
        if (elt && elt.jquery) elt = elt.get(0);
        if (elt) {
            id = elt.id;
            if (id) return id;
        }
        do {
            id = gen();
        } while (document.getElementById(id) || generatedIds.indexOf(id) >= 0);
        if (elt && setEltId && elt.setAttribute) {
            elt.setAttribute('id', id);
        }
        generatedIds.push(id);
        return id;
    }

    function ensureId(elt, prefix) {
        return generateId(prefix && typeof prefix === 'string' ? prefix : 0, elt, true);
    }

    function generateFriendlyId(elt, setEltId, maxWords) {
        var id;
        if (elt && elt.jquery) elt = elt.get(0);
        if (elt) {
            id = elt.id || (elt.tagName === 'a' ? elt.name : '');
            if (id) return id;
        }
        id = getIdentifierFromText(elt.innerText, maxWords);
        if (elt && setEltId && elt.setAttribute) {
            elt.setAttribute('id', id);
        }
        return id;
    }

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    }

    function toTabletPx(px) {
        return px * 0.85;
    }

    function getBaseFontSize() {
        $htmlElt = $htmlElt || $('html');
        return parseFloat($htmlElt.css('font-size'));
    }

    function remToPx(rem) {
        return rem * (Math.round(getBaseFontSize()) || 14);
    }

    function getWindowSize() {
        var width = breakpoints.mobile.max + 1;
        if ((win && win.innerWidth) || (screen && screen.width)) {
            width = win.innerWidth || screen.width;
        }
        return width;
    }

    function isWindowSizeBetween(min, max, winSize) {
        var size = Math.round(winSize || getWindowSize());
        return (min || 0) <= size && (max > 0 ? (size <= max) : true);
    }

    function getResponsiveObj() {
        var winSize = getWindowSize(),
            baseFont = getBaseFontSize();
        function isBetween(min, max) {
            return isWindowSizeBetween(min, max, winSize);
        }
        return {
            windowSize: winSize,
            baseFontSize: baseFont,
            baseFontSizeRounded: Math.round(baseFont),
            breakpoints: breakpoints,
            isMobile: isBetween(0, breakpoints.mobile.max),
            isTablet: isBetween(breakpoints.tablet.min, breakpoints.tablet.max),
            isDesktop: isBetween(breakpoints.desktop.min),
            isDesktopSmall: isBetween(breakpoints.desktop.min, breakpoints.desktop.max),
            isDesktopLarge: isBetween(breakpoints.xl.min)
        };
    }

    function getQueryParamPairs(query) {
        return (query || location.search || '') // Ensure string; use query string if blank
            .replace(/^\??/, '') // Remove question mark
            .split('&') // Split by ampersand
            .filter(s => s); // Remove blanks
    }

    function getQueryParamsObject(query, avoidArrays) {
        if (avoidArrays === undefined && typeof query !== 'string') {
            avoidArrays = query;
            query = null;
        }
        const addVal = (o, a) => {
            const k = a[0], v = a[1], r = o[k];
            if (Array.isArray(r)) r.push(v);
            else if (typeof r === 'string') o[k] = [r, v];
            else o[k] = avoidArrays ? v : [v]; // Set string values for singles if avoidArrays
            return o;
        };
        return getQueryParamPairs(query) // Get key/value pairs
            .map(pair => pair.split('=')) // Split into keys and values
            .reduce(addVal, {}); // Create an object from the pairs
    }

    function getQueryParamNames(query) {
        return getQueryParamPairs(query) // Get key/value pairs
            .map(pair => pair.split('=')[0]) // Get keys
            .filter((v,i,a) => v && a.indexOf(v) === i); // Remove blanks and duplicates
    }

    function getQueryParamVal(name, query) {
        return getQueryParamPairs(query) // Get key/value pairs
            .map(pair => pair.split('=')) // Split into keys and values
            .filter(arr => name === arr[0]) // Match specified key
            .map(arr => arr[1]); // Return values for key
    }

    // Filters pairs by key and value
    function getDistinctQueryPairs(excludeQuery, inQuery) {
        var excludePairs = getQueryParamPairs(excludeQuery); // Get pairs to exclude
        return getQueryParamPairs(inQuery) // Get pairs in the query to trim
            .filter(pair => excludePairs.indexOf(pair) < 0) // Keep only pairs not in the exclude query
            .filter((v,i,a) => a.indexOf(v) === i); // Remove duplicates
    }

    // Filters pairs by key only
    function getDistinctQueryPairsByParamName(excludeQuery, inQuery) {
        var excludeNames = getQueryParamNames(excludeQuery); // Get keys to exclude
        return getQueryParamNames(inQuery) // Get keys in query to trim
            .filter(name => excludeNames.indexOf(name) < 0) // Keep only keys not in the exclude query
            .map(name => getQueryParamVal(name, inQuery).map(v => name + '=' + v)) // Rebuild pairs
            .reduce((acc, v) => acc.concat(v), []) // Flatten array
            .filter((v,i,a) => a.indexOf(v) === i); // Remove duplicates
    }

    function scrollTo($elt, delay, after) {
        var el = $($elt)[0];
        if (el) {
            delay = Math.max(0, delay) || 0;
            win.setTimeout(function () {
                var yScroll = win.scrollY || win.pageYOffset, interval;
                if (typeof yScroll !== 'number') {
                    after();
                    return;
                }
                interval = win.setInterval(function() {
                    if (el.getClientRects()[0].top >= stickyNavOffset) {
                        clearInterval(interval);
                        if (typeof after === 'function') {
                            after();
                        }
                    } else {
                        yScroll -= 10;
                        win.scroll(0, yScroll);
                    }
                }, 5);
            }, delay);
        }
    }

    win.dk = win.dk || {};
    $.extend(true, win.dk, {
        isAuthor: rootHasAuthoringClass || isSitecoreScriptAuthoring,
        isEditing: rootHasEditingClass || isSitecoreScriptEditing,
        getIdentifierFromText: getIdentifierFromText,
        deduplicateIdentifier: deduplicateIdentifier,
        generateId: generateId,
        ensureId: ensureId,
        generateFriendlyId: generateFriendlyId,
        debounce: debounce,
        scrollTo: scrollTo,
        firstOfType: firstOfType,
        toTabletPx: toTabletPx,
        remToPx: remToPx,
        getWindowSize: getWindowSize,
        isWindowSizeBetween: isWindowSizeBetween,
        responsive: $.extend(true, {}, getResponsiveObj()),
        query: {
            getParams: getQueryParamsObject,
            getPairs: getQueryParamPairs,
            getNames: getQueryParamNames,
            getValue: getQueryParamVal,
            getDistinctPairs: getDistinctQueryPairs,
            getDistinctPairsByName: getDistinctQueryPairsByParamName
        },
        lang: 'en' // Updated with actual value on document load
    });

    win.dk.setBodyScrollLock = (function () {
        const bodyStyle = document.body.style,
            props = ['overflow', 'position', 'top', 'width', 'webkitOverflowScrolling'];
        let scrollPosition, isLocked;

        return debounce(function(lock) {
            if (isLocked === lock) return;
            isLocked = lock;
            if (lock) {
                scrollPosition = win.pageYOffset;
                ['hidden', 'fixed', -scrollPosition + 'px', '100%', 'touch'].forEach((v, i) => {
                    if (props[i] in bodyStyle) bodyStyle[props[i]] = v;
                });
            } else {
                props.forEach(p => { if (bodyStyle[p]) bodyStyle.removeProperty(p); });
                if (typeof scrollPosition === 'number') win.scrollTo(0, scrollPosition);
                scrollPosition = null;
            }
        }, 100, true);
    })();

    // Custom browser feature detection
    (function () {
        var bodyStyle = document.body.style,
            add = [];

        if ('webkitLineClamp' in bodyStyle) {
            // Supports -webkit-line-clamp
            add.push('webkitlineclamp');
        }

        if (add.length) rootElt.className += ' ' + add.join(' ');
    })();

    // Set up custom event handlers
    (function () {
        let isFirstHit = true;
        const $win = $(win),
            evaluateLayoutEvents = function (forced) {
                var resp = getResponsiveObj(),
                    prev = win.dk.responsive || {},
                    prevSize = prev.windowSize,
                    prevMobile = prev.isMobile,
                    prevTablet = prev.isTablet,
                    eventArgs = {
                        width: resp.windowSize,
                        oldWidth: prevSize,
                        layoutChanged: isFirstHit || prevMobile !== resp.isMobile,
                        scaleChanged: isFirstHit || prevTablet !== resp.isTablet,
                        isMobile: resp.isMobile,
                        isTablet: resp.isTablet,
                        isForced: forced ? true : false,
                        isFirstHit: isFirstHit
                    };
                $.extend(true, win.dk, { responsive: $.extend(true, {}, resp) });
                if (forced || isFirstHit || prevSize !== resp.windowSize) {
                    $win.trigger('dk:widthChanged', [eventArgs]);
                }
                if (forced || isFirstHit || prevMobile !== resp.isMobile) {
                    $win.trigger('dk:siteLayoutChanged', [resp.isMobile, prevMobile, eventArgs]);
                }
                if (forced || isFirstHit || prevTablet !== resp.isTablet) {
                    $win.trigger('dk:siteScaleChanged', [resp.isTablet, prevTablet, eventArgs]);
                }
                if (isFirstHit) isFirstHit = false;
            },
            siteLayoutHandler = debounce(() => evaluateLayoutEvents(false), 100);

        // Recalculate responsive values on screen size change
        $win.on('load resize orientationchange', siteLayoutHandler);
        $(siteLayoutHandler);
        win.dk.refreshSiteLayout = debounce(() => evaluateLayoutEvents(true), 100, true);
    })();

    // Handle auto-resizing iframes from known sites
    (function () {
        try {
            // Find iframes matching allowed origins
            const originRegex = /nih\.gov$|usrds\.org$/i,
                iframes = [...document.querySelectorAll('iframe')]
                    .filter(i => (new win.URL(i.src, location)).origin.match(originRegex));
            if (!iframes.length) return;
            const onMessage = debounce(function (event) {
                // Make sure the object came from an expected place
                if (!event.origin.match(originRegex)) return;
                const data = event.data || {};
                // Make sure the message is what we expect
                if (data.dkop !== 'iframeheight' || !(data.height > 0)) return;
                for (const iframe of iframes) {
                    // Find the frame that sent the message and set its height
                    if (event.source === iframe.contentWindow) {
                        iframe.setAttribute('height', data.height);
                    }
                }
            }, 500, true); // Debounce to run a max of once every 500ms
            win.addEventListener('message', onMessage);
        } catch (_) { /* ignore */ }
    })();

    // On document loaded
    $(function () {
        var disclaimersPage = $('[data-disclaimers-url]:first').attr('data-disclaimers-url') || '/disclaimers',
            lang = (function () {
                var val = (document.body.className.match(/page-lang-([a-z]{2})/i) || [])[1], elt;
                if (!val) {
                    // Attempt to determine the page language from other metadata and add the body class if it is missing
                    elt = $('[itemprop=inLanguage] meta[itemprop=alternateName]')[0] ||
                        $('meta[property="og:locale"]')[0];
                    val = (elt && elt.content || 'en').slice(0, 2).toLowerCase();
                    $('body').addClass('page-lang-' + val);
                }
                return val;
            })();

        if (navigator.userAgent.match(/iP(ad|hone)/i)) {
            var $meta = $('meta[name=viewport]');
            $meta.attr('content', $meta.attr('content') + ', maximum-scale=1.0');
        }

        // Update dk object with values from DOM
        $.extend(true, win.dk, {
            pages: { disclaimers: disclaimersPage },
            lang: lang
        });

        // Make adjustments to figures
        $('figure,.figure').each(function () {
            let $fig = $(this),
                isDisplayMode = !win.dk.isEditing,
                isSpan = $fig.is('span'),
                $children = $fig.children('.credit,.caption,figcaption,.figcaption');
            if ($children.length > 1) {
                // Ensure that credit and caption are wrapped in a figcaption or .figcaption to ensure both are visible in Firefox
                let $newFigCaption = isSpan ? $('<span class="figcaption"/>') : $('<figcaption/>');
                if (isDisplayMode) $newFigCaption.attr('data-is-adjusted', ''); // Useful to identify modified figures
                $children.each(function () {
                    var $child = $(this), $newChild;
                    if ($child.is('.white')) $newFigCaption.addClass('white');
                    if ($child.is('.pad')) $newFigCaption.addClass('pad');
                    if (isSpan && $child.is('div,p') && !$child.is('.figcaption'))
                        $newChild = $('<span class="' + $child.attr('class') + '"/>').html($child.html());
                    else if ($child.is('figcaption,.figcaption'))
                        $newChild = $('<' + (isSpan ? 'span' : ($child.is('p') ? 'p' : 'div')) + ' class="caption"/>').html($child.html());
                    else $newChild = $child.clone();
                    $newFigCaption.append($newChild.removeClass('white'));
                });
                $children.remove();
                $fig.append($newFigCaption);
            }
            if (isDisplayMode) {
                $('.expand', $fig).each(function () {
                    var a = this, $me = $(a);

                    // Add text for screen readers
                    $me.text('View full-sized image');

                    // Add Akamai Image Manager bypass to expand link
                    if ($me.is('a')) {
                        a.search += (a.search ? '&' : '?') + 'imbypass=true';
                    }
                });
                // Add ARIA role and label for non-semantic (div, span) figures
                if ($fig.is('.figure')) {
                    let $figcap = $('figcaption,.figcaption,.caption,.credit', $fig).first();
                    if (!$fig.is('[role="figure"]')) {
                        $fig.attr('role', 'figure');
                    }
                    if ($figcap.length && !$fig.is('[aria-labelledby]')) {
                        $fig.attr('aria-labelledby', generateId('fig', $figcap, 1));
                    }
                }
            }
        });

        // Markup adjustments that should not be applied in edit mode
        if (!win.dk.isEditing) {
            // Remove inline styling from images to fix additions made by the Sitecore rich text editor.
            // The width and height inline styles are removed by default.
            // Add img[data-allow-style="all"] to keep all inline styles on a particular image,
            //   or list specific styles to keep, like img[data-allow-style-"width height"].
            // Add [data-no-remove-style-img] to any element on the page (e.g., html, body) to disable this behavior globally.
            (function () {
                if ($('[data-no-remove-style-img]').length) return;
                $('.dk-content img[style]').each(function () {
                    function combine(arr) { return arr.map((a) => a[0] + ': ' + a[1]).join('; '); }
                    try {
                        var $img = $(this),
                            presetProps = ['width', 'height', 'min-width', 'min-height', 'max-width', 'max-height'],
                            presets = {
                                default: presetProps.slice(0, 2),
                                dimensions: presetProps
                            },
                            style = $img.attr('style') || '',
                            $root = $img.closest('[data-remove-style-img]'),
                            configVals = ($root.data('remove-style-img') || 'default').trim().toLowerCase().split(/\s+/),
                            bad = Array.prototype.concat.apply([], configVals.map((v) => presets[v] || v)).filter((v, i, a) => a.indexOf(v) === i),
                            allowed = ($img.data('allow-style') || '').trim().toLowerCase().split(/\s+/),
                            allowAll = allowed.indexOf('all') >= 0,
                            remove = bad.filter((v) => !allowAll && allowed.indexOf(v) < 0),
                            arr = (function (s) {
                                var match, regex = /\s*([a-z0-9-]+)\s*:\s*(.+?)\s*(?:;|$)/ig, out = [];
                                while ((match = regex.exec(s)) !== null) out.push([match[1].toLowerCase(), match[2]]);
                                return out;
                            })(style),
                            removedStyle = combine(arr.filter((a) => remove.indexOf(a[0]) >= 0));

                        if (removedStyle) {
                            // Only update if any styles were removed
                            style = combine(arr.filter((a) => remove.indexOf(a[0]) < 0));
                            $img.attr({
                                style: style || null,
                                'data-removed-style': removedStyle
                            });
                        }
                    } catch (_) { /* ignore */ }
                });
            })();

            // Translate data classes into attributes
            // data-attribute-name_value-with_space
            $('[class*="data-"]').each(function () {
                var $me = $(this),
                    cls = ($me.attr('class') || '').split(/\s+/),
                    attrs = {};
                $.each(cls, function (_, v) {
                    var m = v.match(/^(data-[^_]+)(?:_+(.*))?/i) || [],
                        attr = (m[1] || '').toLowerCase(),
                        val = (m[2] || '').replace(/_+/g, ' ').replace(/^ +| +$/g, '');
                    if (attr) {
                        attrs[attr] = val;
                    }
                });
                $me.attr(attrs);
            });

            // Add the url-break class to links where the link text consists of a URL to avoid page overflow
            // Disable per link using the .no-url-break class or data-no-break attribute
            // Disable globally by adding the data-no-auto-break-links attribute on the root html element
            $('html:not([data-no-auto-break-links]) main a[href]:not(.url-ellipsis,.no-url-break):not([data-no-break])').filter(function () {
                return $(this).text().match(/^\s*(https?:|www\.)/i);
            }).addClass('url-break');

            // Add Akamai Image Manager bypass to lesson builder image-resources component
            // and Media Library download links
            const currentHost = $('<a/>').attr('href', location.href).prop('hostname');
            $('.image-resources a,.pt-media-library a.ml-download').each(function () {
                var a = this;
                if (a.hostname === currentHost && a.pathname.match(/\.(gif|jpe?g|png)$/i)) {
                    a.search += (a.search ? '&' : '?') + 'imbypass=true';
                }
            });

            // Add title attribute to YouTube and Facebook iframe elements if not present
            $('iframe:not([title])')
                .filter(function () { return $(this).prop('src').match(/youtu(?:\.be|be\.com)/i); })
                    .attr('title', 'YouTube video')
                .end()
                .filter(function () { return $(this).prop('src').match(/facebook\.com/i); })
                    .attr('title', 'Facebook post');

            // Temporary accessibility fix: remove aria-description in left nav (not yet widely supported)
            $('.dk-leftnav>a[aria-description]').each(function () {
                var $me = $(this), desc = $me.attr('aria-description'), text = $me.text();
                $me.removeAttr('aria-description');
                if (!desc) return;
                $me.attr('aria-label', (text + ' (' + desc.toLowerCase() + ')').trim());
            });
        }
    });
})(window.jQuery, window);
