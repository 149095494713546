(function ($) {
    'use strict';

    $(function () {
        $('.sr-scopes').each(function () {
            var $scopeList = $(this),
                $btnOptions = $('>button:first', $scopeList),
                $menu = $('ul:first', $scopeList);

            function toggleMenu(show) {
                show = typeof show === 'boolean' ? show : !$menu.hasClass('shown');
                $menu[show ? 'addClass' : 'removeClass']('shown');
            }

            $btnOptions.on('click', function (e) {
                toggleMenu();
                e.preventDefault();
                return false;
            }).on('blur', function () {
                toggleMenu(false);
            });
        });

        $('.sr-refine').each(function () {
            var $form = $(this),
                isV2 = $form.is('[data-v2]'),
                $txtQuery = $('input:text:first', $form),
                $withinInputs = $(':radio[name="w"]', $form),
                $prevQuery = $('input[name="wq"]', $form);

            if (isV2) {
                $withinInputs.first().prop('checked', true);
            }
            $form.on('submit', function (e) {
                var val = ($txtQuery.val() || '').replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' '),
                    isWithin = $withinInputs.filter('[value="1"]:first').prop('checked');
                if (val.length > 0) {
                    $txtQuery.val(val);
                    if (isV2) {
                        $withinInputs.removeAttr('name');
                        if (!isWithin) {
                            $prevQuery.removeAttr('name');
                        }
                    }
                    return true;
                } else {
                    e.preventDefault();
                    window.alert('Please enter keywords before searching.');
                    $txtQuery.trigger('focus');
                    if ($txtQuery[0]) {
                        $txtQuery[0].select();
                    }
                    return false;
                }
            });
        });
    });
})(window.jQuery);
