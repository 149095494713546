(function (win, $) {
    'use strict';

    $(function () {
        var dk = win.dk || {};

        function ensureId($elt, prefix) {
            var id = $elt.attr('id');
            if (!id) {
                id = dk.generateId(prefix);
                $elt.attr('id', id);
            }
            return id;
        }

        $('form.dk-form').each(function () {
            var $form = $(this),
                formValidateVal = $form.attr('data-validate') || '',
                isFormValidate = $form.is('[data-validate]'),
                isFormValidateOnBlur = formValidateVal.match(/\bblur\b/i),
                $textFields = $('.field.text', $form);

            $('.field.options', $form).each(function () {
                var $fld = $(this),
                    $lbl = $('.label:first', $fld),
                    $grp = $('.group:first', $fld),
                    lblId = $lbl.attr('id');

                lblId = ensureId($lbl, 'lbl-');
                $grp.attr('aria-labelledby', lblId);

                if ($grp.find('input[type="radio"]').length > 0) {
                    $grp.attr('role', 'radiogroup');
                }

                $('input', $grp).each(function () {
                    var $ipt = $(this),
                        $lbl = $ipt.next('label'),
                        iptId = $ipt.attr('id');

                    iptId = ensureId($ipt, 'ipt-');
                    $lbl.attr('for', iptId);
                });
            });

            // Output "fields"
            $('.field.text', $form).filter(function () { return $('.label,.output', $(this)).length >= 2; }).each(function () {
                var $fld = $(this),
                    $lbl = $('.label:first', $fld),
                    id = ensureId($lbl, 'lbl-');
                $('.output:first', $fld)
                    .attr({ 'tabindex': '0', 'role': 'textbox', 'aria-readonly': 'true', 'aria-labelledby': id })
                    .on('focus', function () {
                        $fld.addClass('focused');
                    }).on('blur', function () {
                        $fld.removeClass('focused');
                    }).on('keypress', function (e) {
                        if (e.which === 13) {
                            $form.trigger('submit');
                        }
                    });
            });

            $textFields.each(function () {
                var $fld = $(this),
                    validateVal = $fld.attr('data-validate') || '',
                    isValidate = isFormValidate || $fld.is('[data-validate]'),
                    validateOnBlur = isFormValidateOnBlur || validateVal.match(/\bblur\b/i),
                    $ipt = $('input,textarea', $fld).first(),
                    $lbl = $ipt.prev('label'),
                    $descriptors = $('.validation,.helper', $fld),
                    descriptorIds = [],
                    iptId = $ipt.attr('id'),
                    reqd = $ipt.is('[required]') || $fld.is('.required'),
                    min = $ipt.attr('min'),
                    max = $ipt.attr('max'),
                    pattern = $ipt.attr('pattern') || '',
                    reg;

                function isValid() {
                    var valStr = ($ipt.val() || '').replace(/^\s+|\s+$/g, ''),
                        valNum = parseFloat(valStr);
                    if (reqd && !valStr) {
                        return false;
                    }
                    if ($.isNumeric(valNum)) {
                        if (($.isNumeric(min) && valNum < min) || ($.isNumeric(max) && valNum > max)) {
                            return false;
                        }
                    }
                    if (pattern) {
                        reg = new RegExp(pattern);
                        return reg.test(valStr);
                    }
                    return true;
                }

                if (reqd) {
                    if (!$ipt.is('[required]')) {
                        $ipt.attr('required', '');
                    }
                    if (!$fld.is('.required')) {
                        $fld.addClass('required');
                    }
                }

                iptId = ensureId($ipt, 'ipt-');
                $lbl.attr('for', iptId);
                $descriptors.each(function () {
                    descriptorIds.push(ensureId($(this), 'div-'));
                });
                if (descriptorIds.length > 0) {
                    $ipt.attr('aria-describedby', descriptorIds.join(' '));
                }

                if (isValidate) {
                    $fld.data('isValid', isValid);
                    $ipt.on('keypress', function () {
                        if ($fld.is('.invalid')) {
                            $fld.removeClass('invalid');
                        }
                    });
                }

                if (!isFormValidate && isValidate) {
                    $fld.attr('formnovalidate', '');
                }

                $ipt.on('focus', function () {
                    $fld.addClass('focused');
                }).on('blur', function () {
                    $fld.removeClass('focused');
                    if (validateOnBlur) {
                        $fld[isValid() ? 'removeClass' : 'addClass']('invalid');
                    }
                });
            });

            if (isFormValidate) {
                $form.attr('novalidate', '');
            }

            $form.data('isValid', isFormValidate ? function () {
                var invalid = 0;
                $textFields.each(function () {
                    var $fld = $(this),
                        validator = $fld.data('isValid'),
                        isValid = true;
                    if (typeof validator === 'function') {
                        isValid = validator();
                        invalid += isValid ? 0 : 1;
                    }
                    $fld[isValid ? 'removeClass' : 'addClass']('invalid');
                });
                return invalid === 0;
            } : function () { return true; });

            $form.on('submit.validate', function (e) {
                if (!$form.data('overrideValidation') && !$form.data('isValid')()) {
                    e.preventDefault();
                    return false;
                }
            });
        });
    });
})(window, window.jQuery);
