(function (win, $) {
    var hostname = (win.location.hostname || '').toLowerCase(),
        pageHref = (win.location.href || '').toLowerCase(),
        dataAttr = 'data-ajax-url',
        dataAttrSel = '[' + dataAttr + ']',
        scriptSel = 'script.ajax-preload';

    // Do nothing if the page is in edit mode
    if (win.dk.isEditing) return;

    // Needed because jQuery doesn't seem to issue the request the same way as preload (based on http://www.ajax-cross-origin.com/how.html)
    function fetchContent(method, url, callback){
        var request = (function () {
            try {
                var xhr = new XMLHttpRequest();
                xhr.open(method, url, true);
                return xhr;
            } catch {
                return null;
            }
        })();
        if (request) {
            if (typeof callback === 'function') request.onload = function () { callback(request.response); };
            request.send();
        }
    }

    // Get all elements with the 'data-ajax-url' attribute
    $(dataAttrSel).filter(function() {
        // Filter out elements that have a parent element with the attribute or that have an empty value for the attribute
        var $elt = $(this);
        return $elt.attr(dataAttr) && !$elt.parents(dataAttrSel).length;
    }).each(function () {
        var $elt = $(this),
            url = $elt.attr(dataAttr),
            $a = $('<a href="' + url + '"/>'),
            ajaxHostname = ($a.prop('hostname') || '').toLowerCase(),
            href = ($a.prop('href') || '').toLowerCase();

        // Ensure the request is to the same hostname (no cross-site requests) and not to the same URL as the page
        if (hostname !== ajaxHostname || href === pageHref) return;

        // Get the replacment element markup via AJAX
        fetchContent('GET', url, data => {
            // Wrap the response content in a div to ensure a single root element
            var $html = $('<div/>').append(data);

            // Remove ajax-preload script if present
            $(scriptSel, $html).remove();

            // Remove the data-ajax-url attribute(s) and replace the outermost one with data-from-ajax
            $(dataAttrSel, $html).each(function(i) {
                var $elt = $(this).removeAttr(dataAttr);
                if (i === 0) $elt.attr('data-from-ajax', '');
            });

            // Replace the existing element with the modified AJAX content
            $elt.replaceWith($html.children());
        });
    });
})(window, window.jQuery);
