(function ($) {
    'use strict';

    $(function () {
        function toggleClass($elt, remove, add) {
            $elt.removeClass(remove).addClass(add);
        }

        // Mobile footer expand/collapse
        $('#main-footer h3').on('click', function () {
            var $t = $(this),
                $icons = $t.find('i'),
                $siblings = $t.siblings('ul'),
                $footer = $('footer'),
                $ulActive = $footer.find('.show');

            if ($ulActive[0] === $siblings[0]) {
                toggleClass($siblings, 'show', null);
                toggleClass($icons, 'i-minus', 'i-plus');
            }
            else {
                toggleClass($ulActive, 'show', null);
                toggleClass($footer.find('.i-minus'), 'i-minus', 'i-plus');
                toggleClass($siblings, null, 'show');
                toggleClass($icons, 'i-plus', 'i-minus');
            }
        });
    });
})(window.jQuery);
