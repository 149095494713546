(function ($, win) {
    'use strict';

    var classCollapsed = 'ln-collapsed',
        dk = win.dk,
        isSpanishPage;

    function getButtonTitleAndLabel($item, isCollapsed) {
        var pageName = $item.text().replace(/\s{2,}/ig, ' '),
            isGroup = $item.parents('.ln-group').length > 0,
            label, title;
        isCollapsed = typeof isCollapsed === 'boolean' ?
            isCollapsed : $item.parents('li:first').is(classCollapsed);
        label = isSpanishPage ? (isCollapsed ? 'Mostrar' : 'Ocultar') : (isCollapsed ? 'Show' : 'Hide');
        title = label +
            (isSpanishPage ? (isGroup ? ' ' : ' las páginas secundarias de ') : (isGroup ? ' ' : ' child pages for ')) +
            (isSpanishPage ? ((isGroup ? 'las páginas de ' : '') + '"' + pageName + '"') : ('"' + pageName + '"' + (isGroup ? ' pages' : '')));
        label += isSpanishPage ? ' las páginas secundarias' : ' child pages';
        return { l: label, t: title };
    }

    // Document ready
    $(function () {
        var longTouch = true,
            scrollTop = 0;

        isSpanishPage = dk.lang === 'es';

        $('.dk-leftnav').each(function () {
            var $ctr = $(this),
                $parents = $('>ul>li', $ctr).filter(function () {
                    return $(this).find('ul').length > 0;
                });
            if ($parents.length > 0) {
                if ($parents.filter(':not(.ln-group)').length > 0) {
                    $('>ul', $ctr).addClass('ln-expandable');
                }
                $parents.each(function () {
                    var $li = $(this),
                        $item = (function () {
                            var $i = $('>div>*', $li).first();
                            return $i.length ? $i : $('>:first', $li);
                        })(),
                        ulId = dk.ensureId($('ul', $li), 'ln-ul-'),
                        isGroup = $li.is('.ln-group'),
                        isCurrent = $li.is('.ln-current'),
                        isDefaultExpanded = isGroup || $li.is('.ln-default-expanded'),
                        isCollapsed = !isCurrent && !isDefaultExpanded,
                        titleLabel = getButtonTitleAndLabel($item, isCollapsed),
                        $button = $('button', $li).first(),
                        itemId;
                    if (!$button.length) {
                        $button = $('<button type="button"/>').html(isGroup ? $item.html() : titleLabel.l);
                        $li.prepend(
                            $('<div/>').append(
                                isGroup ? null : $item,
                                $button
                            )
                        );
                        if (isGroup) $item.remove();
                    }
                    itemId = isGroup ? null : dk.ensureId($item),
                    $button.attr({
                        'title': titleLabel.t,
                        'aria-label': isGroup ? titleLabel.t : null,
                        'aria-controls': ulId,
                        'aria-expanded': isCollapsed ? null : 'true',
                        'aria-describedby': itemId
                    }).before(' ');
                    if (!isGroup) $button.text(titleLabel.l);
                    if (isCollapsed) $li.addClass(classCollapsed);
                    $button.off().on('click', function (e) {
                        var $btn = $(this),
                            $l = $btn.parents('li:first'),
                            $ul = $('ul', $l),
                            isColl = $l.hasClass(classCollapsed);
                        e.preventDefault();
                        $ul[isColl ? 'slideDown' : 'slideUp'](300, function () {
                            var isC = $li.toggleClass(classCollapsed).hasClass(classCollapsed),
                                btnTitleLabel = getButtonTitleAndLabel(isGroup ? $btn : $item, isC);
                            $btn.attr({
                                'title': btnTitleLabel.t,
                                'aria-label': isGroup ? btnTitleLabel.t : null,
                                'aria-expanded': isC ? null : 'true'
                            });
                            if (!isGroup) $btn.text(btnTitleLabel.l);
                        });
                        return false;
                    });
                });
            }
        });

        $('nav').on('touchstart', function (e) {
            if ($(this).hasClass('dk-leftnav related')) {
                e.stopPropagation();
            }
        });

        // Contextual drop-down menu for mobile
        $('.context-menu-button').on('click', function () {
            $('.dk-leftnav').toggleClass('related');
            $('.context-menu-button').toggleClass('open');
        });

        /* checks if touch is shorter than 200ms and window has scrolled less than 3 pixels */
        $('.site-wrap').on('touchstart', function () {
            setTimeout(function () { longTouch = true; }, 200);
            longTouch = false;
            scrollTop = $(win).scrollTop();
        });

        $('.site-wrap').on('click touchend', function () {
            if ((longTouch === false) && (Math.abs($(win).scrollTop() - scrollTop) < 3)) {
                $('.dk-leftnav').removeClass('related');
                $('.context-menu-button').removeClass('open');
            }
            longTouch = true;
        });

        $('.context-menu-button').on('click touchstart touchend', function (e) {
            e.stopPropagation();
        });

        $('button[name="options"], button.mobile-site-search, button.dk-nav-menu').on('click', function () {
            $('.dk-leftnav').removeClass('related');
            $('.context-menu-button').removeClass('open');
        });
    });
})(window.jQuery, window);
