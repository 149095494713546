(function ($) {
    'use strict';

    if (!window.dk.isEditing) {
        $(function () {
            var currentPathName = (location.pathname || '').toLowerCase().replace(/\/$/, ''),
                $arrows = $('.rtcd-career-arrow');
            if (!$arrows.length) {
                return;
            }
            $arrows.filter(':not([data-no-javascript]):not(.formatted)').each(function () {
                var $arrow = $(this),
                    $list = $arrow.clone();
                $list.children().each(function () {
                    var $li = $(this),
                        $a = $('a:first', $li),
                        pathName = ($a.prop('pathname') || '').toLowerCase().replace(/\/$/, ''),
                        isActive = pathName === currentPathName;
                    if (isActive) {
                        $li.addClass('active');
                    }
                    $a.wrapInner('<span><span></span></span>');
                    if (isActive) {
                        $li.empty().append(
                            $('<span/>').append($a.contents())
                        ).data('href', $a.prop('href'));
                    }
                });
                $arrow.empty().append($list.children()).addClass('formatted');
            });
            $arrows.addClass('formatted');
        });
    }
})(window.jQuery);
