(function (win, $) {
    'use strict';

    var count = 0,
        dk = win.dk;

    function shouldBeOpened(collapse) {
        var isMobile = dk.responsive.isMobile;
        collapse = collapse || 'mobile';
        return !((isMobile && collapse.match(/mobile|both/)) ||
            (!isMobile && collapse.match(/full|both/)));
    }

    function setState($ctr, isClick, callback) {
        var data = $ctr.data() || { collapse: 'mobile' },
            $exp = $('.expandable', $ctr),
            $btn = $('.expandable-toggle', $ctr),
            isOpened = $btn.is('[aria-expanded=true]'),
            shouldOpen = isOpened,
            isChanged;

        if (isClick) {
            $ctr.data('dirty', true);
            shouldOpen = !isOpened;
        }
        else if (!data.dirty) {
            isChanged = shouldBeOpened(data.collapse) !== isOpened;
            shouldOpen = isChanged ? !isOpened : isOpened;
        }
        $exp[shouldOpen ? (isClick ? 'slideDown' : 'show') : (isClick ? 'slideUp' : 'hide')](function () {
            $btn.text($btn.data()[shouldOpen ? 'c' : 'e'])
                .attr('aria-expanded', shouldOpen);
            if (isClick) {
                $btn.focus();
            }
            if (typeof callback === 'function') callback();
        });
    }

    $(function () {
        // Don't change markup in edit mode
        if (dk.isEditing) return;

        $('.expandable').each(function () {
            var $origExp = $(this),
                $ctr = $('<div class="dk-expandable"/>'),
                $btn = $('<button class="expandable-toggle"/>'),
                $exp = $origExp.clone().attr({ role: 'region', tabindex: -1, 'aria-labelledby': dk.ensureId($btn) }),
                collapse = ($exp.attr('data-collapse') || 'mobile').toLowerCase().replace(/\s*/g, '');
            $ctr.data('collapse', collapse).append(
                $('<div/>').append(
                    $btn.data({
                        e: $exp.attr('data-expand-text') || 'Show description',
                        c: $exp.attr('data-collapse-text') || 'Hide description'
                    }).attr('aria-controls', dk.ensureId($exp)).on('click', function (e) {
                        e.preventDefault();
                        setState($ctr, true);
                        return false;
                    })
                ),
                $exp
            );
            setState($ctr, false, function () {
                $exp.addClass('ready');
                $origExp.replaceWith($ctr);
            });
            count++;
        });

        if (count > 0) {
            $(win).on('dk:siteLayoutChanged', function () {
                $('.dk-expandable').each(function () {
                    setState($(this));
                });
            });
        }

        // Content fold ("More" button) in a new-style feature box
        $('.box .fold,.foldable .fold').each(function () {
            var $fold = $(this),
                wrapId, $wrap, $folded,
                $cnt = $fold.nextAll().not('.unfold,.unfold~*'),
                attrs = {
                    'aria-hidden': true,
                    'tabindex': -1
                };

            function setFold() {
                if (!$wrap) return;
                $fold.removeAttr('aria-expanded')
                    .after($wrap)
                    .off('click').on('click', function (e) {
                        e.preventDefault();
                        $(this).remove();
                        if ($folded) $folded.attr('aria-expanded', true);
                        dk.scrollTo($wrap, 0, function () {
                            $wrap.removeAttr('aria-hidden').trigger('focus');
                            dk.scrollTo($wrap);
                        });
                        return false;
                    });
            }

            function foldKeydown(e) {
                if (e.which === 13 || e.which === 32) {
                    e.preventDefault();
                    $(this).trigger('click');
                    return false;
                }
            }

            if ($cnt.length) {
                $wrap = $cnt.length == 1 ? $cnt : $('<div/>').append($cnt);
                $wrap.attr(attrs).addClass('folded-content no-outline no-child-bookends');
                $folded = $('> .folded', $wrap);
                wrapId = dk.generateId('fldc-', $wrap, 1);

                setFold();

                $folded.on('click', function (e) {
                    e.preventDefault();
                    $(this).removeAttr('aria-expanded');
                    $wrap.attr(attrs).before($fold.off('keydown').on('keydown', foldKeydown));
                    setFold();
                    dk.scrollTo($fold, 0, function () {
                        dk.scrollTo($fold);
                    });
                    return false;
                });

                $fold.add($folded).attr({
                    'tabindex': 0,
                    'role': 'button',
                    'aria-controls': wrapId
                }).on('keydown', foldKeydown);
            } else {
                $fold.remove();
            }
        });

        // Expanding alert (e.g., COVID-19 banner)
        $('.expanding-alert').each(function () {
            var $wrap = $(this).addClass('collapsed').attr('tabindex', -1),
                $below = $('.expand-below', $wrap).attr('tabindex', -1).hide().removeClass('hide'),
                belowId = dk.ensureId($below),
                isBusy,
                $above = $('.expand-above', $wrap).attr({
                    tabindex: 0,
                    role: 'button',
                    'aria-controls': belowId,
                    'aria-expanded': false
                }),
                noAutoTitle = $above.is(['data-no-auto-title']),
                origTitle = ($above.attr('title') || $above.data('title') || '').replace(/\{text([+-])?\}/, (_, mod) => {
                    var txt = ($above.text() || '').trim().replace(/\s{2,}/g, ' ');
                    if (!txt) return txt;
                    if (mod === '+') return txt.charAt(0).toUpperCase() + txt.slice(1);
                    if (mod === '-') return txt.charAt(0).toLowerCase() + txt.slice(1);
                    return txt;
                }),
                setTitle = origTitle && !noAutoTitle;

            function getTitleText(isOpening) {
                return origTitle.replace(
                    /\{([^|}]+)(?:\|([^}]+))?\}/g,
                    function (_, show, hide) { return (isOpening && hide) ? hide : show; }
                );
            }

            function doToggle(event) {
                var isOpening;
                event.preventDefault();
                if (isBusy) return false;
                isBusy = true;
                isOpening = $wrap.hasClass('collapsed');
                $below[isOpening ? 'slideDown' : 'slideUp'](100, function () {
                    $wrap[isOpening ? 'removeClass' : 'addClass']('collapsed');
                    $above.attr('aria-expanded', isOpening);
                    if (setTitle) $above.attr('title', getTitleText(isOpening));
                    (isOpening ? $below : $wrap).trigger('focus');
                    isBusy = false;
                });
                return false;
            }

            if (setTitle) $above.attr('title', getTitleText(false));
            $above.removeAttr('data-title').on('click', doToggle)
                .on('keydown', function (e) {
                    if (e.which === 13 || e.which === 32)
                        return doToggle(e);
                });
        });
    });
})(window, window.jQuery);
