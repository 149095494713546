(function (win, $) {
    'use strict';

    var $sliders = $('.sc-slider'),
        isTwoColumn = $('.dk-two-column').length > 0,
        debounce = win.dk.debounce;

    function setHeight() {
        $sliders.each(function () {
            var $slider = $(this),
                cardHeights = [];

            $('.sc-card', $slider).each(function () {
                cardHeights.push(this.offsetHeight);
            }).css('height', Math.max.apply(null, cardHeights));
        });
    }

    $sliders.each(function (idx) {
        var id = 'sc-slider-' + idx,
            $slider = $(this).attr('id', id),
            describeTo;

        function slides(oneColumn, twoColumns) {
            return isTwoColumn ? twoColumns : oneColumn;
        }

        $('.sc-cards', $slider).on('afterChange', function (_, slick) {
            clearTimeout(describeTo);
            describeTo = setTimeout(function () { slick.$slides.removeAttr('aria-describedby'); }, 100);
        }).slick({
            speed: 300,
            slidesToShow: slides(4, 3),
            slidesToScroll: slides(4, 3),
            prevArrow: '#' + id + ' .sc-slider-paginator button.sc-previous',
            nextArrow: '#' + id + ' .sc-slider-paginator button.sc-next',
            infinite: true,
            dots: true,
            respondTo: 'window',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: slides(3, 2),
                        slidesToScroll: slides(3, 2)
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        infinite: false
                    }
                },
                {
                    breakpoint: 321,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '25px',
                        infinite: false
                    }
                }
            ]
        });

        $('.slick-slide').removeAttr('aria-describedby');
    });

    if ($sliders.length > 0) {
        $(win).on('dk:widthChanged', debounce(function () {
            setHeight();
            $('.slick-dots').each(function () {
                var $dots = $(this);
                $dots[$dots.children().length > 1 ? 'show' : 'hide']();
            });
        }, 250));
        $(function () {
            var i;
            for (i = 1; i <= 3; i++) {
                // Adjust the height three times after document load with increasing time between to ensure the content fits
                win.setTimeout(setHeight, 500 * i);
            }
        });
    }
})(window, window.jQuery);
