(function (win, $) {
    'use strict';

    // On document ready
    $(function () {
        var dk = win.dk || {},
            ariaHidden = 'aria-hidden',
            ariaSelected = 'aria-selected',
            ariaControls = 'aria-controls',
            ariaOrientation = 'aria-orientation',
            attrTabindex = 'tabindex',
            isMobile = dk.responsive.isMobile,
            pageTitle = $('title').text() || 'NIDDK';

        function setAria($elt, prop, val, allowFalse) {
            var fval = typeof val === 'string' ? val : (val ? 'true' : 'false');
            if (val || allowFalse) {
                $elt.attr(prop, fval);
            } else {
                $elt.removeAttr(prop);
            }
        }

        $(win).on('dk:siteLayoutChanged', function (_, ism) {
            isMobile = ism;
            setAria($('.tab-sections .tab-bar'), ariaOrientation, ism ? 'vertical' : '');
        });

        // Set up the tab sections component
        $('.tab-sections').each(function () {
            var $ctr = $(this),
                useHistory = !$ctr.is('[data-no-history]'),
                $menu = $('.tab-menu', $ctr),
                $bar = $('.tab-bar', $ctr),
                secToTab = {},
                $tabs = $bar.children(),
                $sections = $ctr.children(':not(.tab-menu):not(.tab-bar)'),
                $activeTab, $activeSection,
                path = location.pathname.replace(/\/$/, ''),
                keyEventBound = false,
                firstSectionId,
                otherParams;

            function $queryTab() {
                var arr, $tab;
                if (!useHistory) {
                    return $tabs.first();
                }
                arr = (location.search || '').replace(/\?|=[^&]*|&/g, ' ').replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' ').split(' ');
                $.each(arr, function (_, v) {
                    if (secToTab[v]) {
                        $tab = $('#' + secToTab[v]);
                        return false;
                    }
                });
                return $tab || $tabs.first();
            }

            function $sectionForTab($tab) {
                return $('#' + $tab.attr(ariaControls));
            }

            function updateActiveTabDisplay($tab, $sec) {
                if (!$tab || !$tab.length) {
                    return;
                }
                $sec = $sec || $sectionForTab($tab);
                if (!$sec || !$sec.length) {
                    return;
                }
                setAria($sections, ariaHidden, 1);
                setAria($sec, ariaHidden, 0);
                $sections.attr(attrTabindex, -1);
                $sec.attr(attrTabindex, 0);
                $tabs.attr(attrTabindex, -1);
                $tab.attr(attrTabindex, 0);
                setAria($tabs, ariaSelected, 0);
                setAria($tab, ariaSelected, 1);
                $menu.text($tab.text());
                $activeTab = $tab;
                $activeSection = $sec;
            }

            function setActiveTab($tab, focusSection, initialLoad) {
                var $sec, tid, sid, arr = [];
                if (!$tab || !$tab.length) {
                    return;
                }
                $sec = $sectionForTab($tab);
                if (!$sec || !$sec.length) {
                    return;
                }
                $activeTab = $tab;
                $activeSection = $sec;
                // Update history
                if (useHistory) {
                    tid = $tab.attr('id');
                    sid = $sec.attr('id');
                    sid = sid === firstSectionId ? '' : sid;
                    if (sid) {
                        arr.push(sid);
                    }
                    if (initialLoad && otherParams) {
                        arr.push(otherParams);
                    }
                    win.history.replaceState(
                        { tabId: tid, sectionId: sid },
                        pageTitle,
                        path + (arr.length > 0 ? ('?' + arr.join('&')) : '')
                    );
                }
                updateActiveTabDisplay($tab, $sec);
                if (!initialLoad) {
                    if (isMobile && focusSection) {
                        $sec.trigger('focus');
                    } else {
                        $tab.trigger('focus');
                    }
                }
            }

            function $previousTab() {
                return $tabs.first().is($activeTab) ? $tabs.last() : $activeTab.prev();
            }

            function $nextTab() {
                return $tabs.last().is($activeTab) ? $tabs.first() : $activeTab.next();
            }

            // Ensure tab structure and set up as necessary
            $sections.each(function () {
                var $sec = $(this),
                    sid = dk.generateId('ts_', $sec),
                    tid = 't_' + sid;
                secToTab[sid] = tid;
                $sec.attr({ id: sid, role: 'tabpanel', 'aria-labelledby': tid });
            });
            firstSectionId = $sections.first().attr('id');
            if (!$bar.length || $tabs.length !== $sections.length) {
                $bar = $('<div class="tab-bar" aria-label="Tabbed display: use arrow keys to navigate among tabs"/>');
                $sections.each(function (i) {
                    var $sec = $(this),
                        title = $('h1:first', $sec).text() || ('Tab ' + (i + 1));
                    $('<span/>').text(title).appendTo($bar);
                });
                $tabs = $bar.children();
                $ctr.prepend($bar);
            }
            $bar.attr('role', 'tablist');
            setAria($bar, ariaOrientation, isMobile ? 'vertical' : '');
            $tabs.each(function (i) {
                var $tab = $(this),
                    sid = Object.keys(secToTab)[i];
                $tab.attr({
                    id: secToTab[sid],
                    role: 'tab',
                    'aria-controls': sid
                }).on('click', function (e) {
                    e.preventDefault();
                    setActiveTab($(this), 1);
                    return false;
                });
            }).on('focus', function () {
                $bar.addClass('shown');
                if (!keyEventBound) {
                    $(document).on('keydown.tabsec', function (e) {
                        var w = e.which,
                            m = isMobile,
                            $tab;
                        // 13: Enter, 27: Escape
                        if (m && (w === 13 || w === 27)) {
                            $bar.removeClass('shown');
                            $activeSection.trigger('focus');
                            return false;
                        }
                        // 37: Left, 38: Up, 39: Right, 40: Down
                        if (!m && w == 37 || m && w === 38) {
                            $tab = $previousTab();
                        } else if (!m && w === 39 || m && w === 40) {
                            $tab = $nextTab();
                        }
                        if ($tab) {
                            e.preventDefault();
                            setActiveTab($tab);
                            return false;
                        }
                    });
                    keyEventBound = true;
                }
            }).on('blur', function () {
                var doc = document;
                if (!$(doc.activeElement).is($tabs)) {
                    $(doc).off('keydown.tabsec');
                    keyEventBound = false;
                    $bar.removeClass('shown');
                }
            });
            if (!$menu.length) {
                $menu = $('<div class="tab-menu"/>').prependTo($ctr);
            }
            $menu.attr('role', 'presentation')
                .on('click', function (e) {
                    e.preventDefault();
                    $bar.toggleClass('shown');
                    if ($bar.hasClass('shown')) {
                        $activeTab.trigger('focus');
                    }
                    return false;
                });

            otherParams = dk.query.getDistinctPairsByName(Object.keys(secToTab).join('&')).join('&');

            updateActiveTabDisplay($queryTab());
            setActiveTab($queryTab(), 0, 1);

            // Bind the history event
            if (useHistory) {
                win.addEventListener('popstate', function (e) {
                    updateActiveTabDisplay($('#' + e.state.tabId), null, 1, 0);
                });
            }
        });
    });
})(window, window.jQuery);
