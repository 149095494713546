(function (win, $) {
    $(function () {
        $('.fs-return-link>a[data-return-text],.fs-return-link.hide-no-ref>a').each(function (_, a) {
            if (a.protocol.startsWith('javascript')) {
                $(a).parent().removeClass('hide-no-ref');
                return;
            }

            const referrer = win.document.referrer;
            if (!referrer) return;

            const ref = $('<a/>').attr('href', referrer)[0],
                loc = win.document.location;
            if (loc.hostname !== ref.hostname || loc.port !== ref.port) return;

            const $a = $(a);
            if (ref.hostname !== a.hostname || ref.port !== a.port || ref.pathname !== a.pathname) return;

            const hist = win.history || { length: 0 };
            const histLength = hist.length;

            $a.attr('href', ref.href);
            if (!$a.is('[data-no-history]')) {
                $a.off().on('click', function (e) {
                    if (hist.length <= 1 || !hist.go) return;
                    const diff = hist.length - histLength + 1;
                    if (diff !== 1) return;
                    e.preventDefault();
                    hist.go(-diff);
                    return false;
                });
            }

            const text = $a.data('return-text');
            if (text) $a.text(text);

            $a.parent().removeClass('hide-no-ref');
        });
    });
})(window, window.jQuery);
