// Legacy GFR Calculator UI logic

import * as Gfr from './gfr-lib-legacy';

(function ($) {
    'use strict';

    if (!$ || !Gfr) {
        return;
    }

    $(function () {
        $('form.gfr-calculator').not('.v2').each(function () {
            var $form = $(this),
                calc, type, isChild = $form.is('.child'),
                units = $form.is('.si') ? Gfr.Units.SI : Gfr.Units.Conventional,
                lastResult;

            function setOutput(val) {
                $('.result', $form).each(function () {
                    var $t = $(this);
                    $t[$t.is('input') ? 'val' : 'text'](val || '');
                });
                lastResult = val;
            }

            function clearResult() {
                if (lastResult) {
                    setOutput();
                }
            }

            if ($form.data('initialized')) {
                return;
            }

            $form.data('overrideValidation', 1).data('initialized', 1);

            if (isChild) {
                calc = new Gfr.ChildCalculator(units);
            } else {
                type = $form.is('.ckd-epi') ? Gfr.Type.CkdEpi : Gfr.Type.Mdrd;
                calc = new Gfr.AdultCalculator(units, type);
            }

            $('button', $form).on('click', function (e) {
                e.preventDefault();
                $form.trigger('submit');
                return false;
            });

            $('input', $form).on('keydown change', function (e) {
                if (e.type === 'keydown' && (e.which === 9 || e.which === 13 || (e.which === 16 && e.shiftKey))) {
                    return true;
                }
                clearResult();
            });

            $form.on('submit', function (e) {
                var result, data,
                    validator = $form.data('isValid') || function () {
                        return true;
                    };
                e.preventDefault();
                if (!validator()) {
                    clearResult();
                    $('.invalid:first input', $form).trigger('focus').trigger('select');
                    return false;
                }
                data = (function () {
                    var map = { scr: 0, age: 0, race: 0, gender: 0, height: 0 };
                    $.each($form.serialize().split('&'), function (i, pair) {
                        var p = pair.split('='), f = parseFloat(p[1]);
                        map[p[0]] = $.isNumeric(f) ? f : 0;
                    });
                    return map;
                })();
                result = isChild ? calc.calculate(data.scr, data.height) :
                    calc.calculate(data.scr, data.age, data.race, data.gender);
                setOutput(result);
                $('.output:first', $form).trigger('focus');
                return false;
            });
        });
    });
})(window.jQuery);
