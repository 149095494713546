(function (win, $) {
    'use strict';

    const dk = win.dk,
        isEdit = dk.isEditing,
        containerClass = 'youtube-container',
        containerSelector = '.' + containerClass,
        figureSelector = 'figure,.figure',
        ytFigureClass = 'youtube-figure',
        ytFigureSelector = '.' + ytFigureClass,
        // Elements matching the following selectors will be excluded
        excludedElementSelectors = [
            '.no-wrap'
        ].join(','),
        // Elements with parent elements matching the following selectors will be excluded
        excludedParentSelectors = [
            '.no-youtube-wrap',
            containerSelector,
            '.youtube-summary',
            '.ri-video',
            '.ri-popup-image',
            figureSelector
        ].join(',');

    function wrapIframe(elt) {
        const fw = 'full-width';
        const $iframe = elt.jquery ? elt : $(elt),
            wrapElt = $iframe.parents('span,p').length ? 'span' : 'div',
            $wrap = $('<'+wrapElt+'/>').addClass(containerClass);
        if (!isEdit) $wrap.attr('data-auto-wrap', '');
        if (!$iframe.is('.no-'+fw+',[data-no-'+fw+']')) $wrap.addClass(fw);
        $iframe.wrap($wrap);
    }

    function evaluateYouTubeEmbeds(parent) {
        const $youtubeIframes = $(parent || 'main').find('iframe[src]').filter(function () {
            return ($(this).prop('src') || '').match(/youtu\.?be/);
        });

        // Identify iframes to process then handle each of them
        $youtubeIframes.filter(function () {
            const $me = $(this);
            return !$me.is(excludedElementSelectors) &&
                !$me.parents(excludedParentSelectors).length;
        }).each(function () { wrapIframe(this); });

        // Ensure correct structure for YouTube embeds in responsive figures
        $youtubeIframes.filter(function () {
            return $(this).parents(figureSelector).length;
        }).each(function () {
            const $me = $(this),
                $figure = $me.parents(figureSelector).first();
            if (!$me.parents(containerSelector).length) wrapIframe($me);
            if (!$figure.is(ytFigureSelector)) {
                $figure.addClass(ytFigureClass).attr('data-auto-wrap', '');
            }
            $figure.attr('data-fixed', '');
        });
    }

    dk.evaluateYouTubeEmbeds = function (parent) { if (!isEdit) evaluateYouTubeEmbeds(parent); };

    // Document ready
    $(function () { evaluateYouTubeEmbeds(); });
})(window, window.jQuery);
