(function (win, $) {
    'use strict';

    var dk = win.dk || {},
        cls = 'overflow',
        titleAttr = 'title';

    function setOverflow() {
        var $me = $(this), isNoAutoTitle = $me.is('[data-no-auto-title]');
        if (Math.ceil(this.getBoundingClientRect().height) + 1 < this.scrollHeight) {
            $me.addClass(cls);
            if (!isNoAutoTitle) {
                $me.attr(titleAttr, $me.text());
            }
        } else if ($me.hasClass(cls)) {
            $me.removeClass(cls);
            if (!isNoAutoTitle) {
                $me.removeAttr(titleAttr);
            }
        }
    }

    function setOverflowAll() {
        $('.line-clamp').each(setOverflow);
    }

    dk.resetLineClamp = function ($clamp) {
        if ($clamp && $clamp.jquery && $clamp.length) {
            $clamp.each(setOverflow);
        } else {
            setOverflowAll();
        }
    };

    if (!dk.isEditing) {
        $(win).on('dk:widthChanged', win.dk.debounce(setOverflowAll, 250));
    }
})(window, window.jQuery);
