(function (win, $) {
    'use strict';

    if (!win.dk.isEditing) {
        $(function () {
            function isOverflowing($elt) {
                var e = $elt[0];
                return e && e.scrollWidth > e.clientWidth + 1; // Add 1 because IE rounds scrollWidth up sometimes
            }

            function updateTopScroll() {
                $('.dk-table-wrap').each(function () {
                    var $wrap = $(this),
                        $topScroll = $wrap.prev('.dk-top-scroll'),
                        over = isOverflowing($wrap);
                    $topScroll[over ? 'removeClass' : 'addClass']('hidden');
                    $topScroll.find('div').width($wrap.find('table:first').width());
                });
            }

            // Tablesaw
            if (win.Tablesaw) win.Tablesaw.init();

            // Fix accessibility for Tablesaw column nav buttons
            $('.tablesaw-nav-btn').each(function () {
                var $btn = $(this);
                $btn.attr('aria-label', $btn.attr('title'));
            });

            // Set up slide tables (non-Tablesaw)
            $('main table:not([class*="tablesaw-"],[data-no-wrap],.result-items)')
                .wrap('<div class="dk-table-wrap" tabindex="0"/>');
            $('.dk-table-wrap').each(function () {
                var $wrap = $(this),
                    addTopScroll = !$('table:first', $wrap).is('[data-no-top-scroll]'),
                    $topScroll;
                if (addTopScroll) {
                    $topScroll = $('<div class="dk-top-scroll" aria-hidden="true"/>').append(
                        $('<div/>').html('&#160;')
                    );
                    $wrap.before($topScroll);
                    $wrap.on('scroll', function () {
                        $topScroll.scrollLeft($wrap.scrollLeft());
                    });
                    $topScroll.on('scroll', function () {
                        $wrap.scrollLeft($topScroll.scrollLeft());
                    });
                }
            });
            updateTopScroll();

            $(win).on('resize orientationchange', updateTopScroll);
        });
    }
})(window, window.jQuery);
