(function ($) {
    'use strict';

    $(function initializeGovBanner() {
        $('.usa-banner').each(function () {
            var $content = $('.usa-banner__content', this),
                $header = $('.usa-banner__header', this);
            $('.usa-banner__button', this)
                .off()
                .on('click', function () {
                    var $me = $(this),
                        expand = $me.attr('aria-expanded') !== 'true';
                    $content.attr('hidden', expand ? null : '');
                    $me.attr('aria-expanded', expand);
                    $header[expand ? 'addClass' : 'removeClass'](
                        'usa-banner__header--expanded'
                    );
                });
        });
    });
})(window.jQuery);

