(function ($) {
    'use strict';

    $(function () {
        if (!window.dk.isEditing) {
            // For tabs in body content, generate the tab structure when not in edit mode
            $('.tabs').each(function () {
                var $tabsContainer = $(this).removeClass('tabs').addClass('dk-tabs'),
                    $tabHeader = $('<div class="dk-tabs-header"/>'),
                    $dkTabContentContainer = $('<div class="dk-tabs-content"/>'),
                    $holder = $(null),
                    j, h;
                for (j = 1; j <= 6; j++) {
                    if ($tabsContainer.children('h' + j).length > 0) {
                        h = 'h' + j;
                        break;
                    }
                }
                if (!h) {
                    return; // Could not find headers for tabs
                }
                $tabsContainer.children().each(function () {
                    var $me = $(this);
                    if ($me.is(h) && !$me.is('[data-literal]')) {
                        $dkTabContentContainer.append($holder);
                        $holder = $('<div/>');
                        $tabHeader.append($('<span/>').html($me.html()));
                    } else {
                        $holder.append($me.clone());
                    }
                });
                $dkTabContentContainer.append($holder);
                $tabsContainer.empty().append(
                    $tabHeader,
                    $dkTabContentContainer
                );
            });
        }

        $('.dk-tabs').each(function (ctrIdx) {
            var $tabsContainer = $(this),
                $tabHeader = $('.dk-tabs-header', $tabsContainer),
                attrSel = 'aria-selected',
                generateId = window.dk.generateId,
                $tabSet, $dkTabContentContainer, $dkTabContent, $tabs, $tabsItems,
                sd = 'scroll_distance', cfg = {};

            function updateScrollableClass() {
                // Check if tabs exist, if they don't force 0 so that the
                // class is never added.
                var scrollWidth = $tabs.length > 0 ? $tabs[0].scrollWidth : 0,
                    scrollableClass = 'dk-scrollable',
                    panelWidth = $tabs.outerWidth();

                if ($tabsContainer.hasClass(scrollableClass)) {
                    if (scrollWidth <= panelWidth) {
                        $tabsContainer.removeClass(scrollableClass);
                    }
                } else {
                    if (scrollWidth > panelWidth) {
                        $tabsContainer.addClass(scrollableClass);
                    }
                }
            }

            cfg[sd] = 125; // scroll_distance
            $tabSet = $tabHeader.scrollTabs(cfg);
            $dkTabContentContainer = $('.dk-tabs-content', $tabsContainer);
            $dkTabContent = $dkTabContentContainer.children();
            $tabs = $('.scroll_tab_inner', $tabsContainer);
            $tabsItems = $tabs.children('span:not([class*="_finisher"])');

            $('.dk-tabs-content > div', $tabsContainer).each(function () {
                var $this = $(this);
                $this.attr({
                    id: generateId('tabct-' + ctrIdx + '-'),
                    role: 'tabpanel'
                }).prop('hidden', true);
            });

            $($dkTabContent).first().addClass('show').prop('hidden', false)
                .siblings().prop('hidden', true);
            $('.scroll_tab_first', $tabsContainer).attr(attrSel, true);
            $tabs.attr('tabindex', '0').attr('role', 'tablist');
            updateScrollableClass();

            $('span:not([class*="_finisher"])', $tabSet.domObject).each(function (i) {
                var $tab = $(this),
                    $content = $dkTabContent.eq(i);

                $tab.attr('role', 'tab');
                if ($content) {
                    $tab.attr('aria-controls', $content.attr('id'));
                }

                $tab.on('click', function () {
                    $('.show', $dkTabContentContainer).removeClass('show');

                    if ($content) {
                        $content.addClass('show').prop('hidden', false)
                            .siblings().prop('hidden', true);
                    }

                    $tab.attr(attrSel, true)
                        .siblings().attr(attrSel, 'false');
                });
            });

            $(window).on('resize', function () {
                updateScrollableClass();
            });

            $(document).on('keydown', function (e) {
                var w = e.which,
                    $active = $(document.activeElement),
                    $target, $first, $last, $sel;
                // 37: left, 39: right
                if ($active.is($tabs)) {
                    if ($.inArray(w, [37, 39]) > -1) {
                        e.preventDefault();
                        $first = $tabsItems.filter(':first');
                        $last = $tabsItems.filter(':last');
                        $sel = $tabsItems.filter('[' + attrSel + '="true"]:first');

                        if (w === 37 && $sel.is($first)) {
                            $target = $last;
                        } else if (w === 39 && $sel.is($last)) {
                            $target = $first;
                        } else {
                            $target = $sel[w === 37 ? 'prev' : 'next']();
                        }
                        if ($target.length < 1) {
                            $target = $first;
                        }
                        $target.trigger('click');

                        return false;
                    }
                }
            });
        });
    });

})(window.jQuery);
