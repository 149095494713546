(function ($, win) {
    'use strict';

    var classCollapsed = 'tn-collapsed',
        classMenuOpen = 'tn-menu-open',
        dk = win.dk;

    function getButtonState($item, isCollapsed) {
        isCollapsed = typeof isCollapsed === 'boolean' ?
            isCollapsed : $item.parents('li:first').is(classCollapsed);
        return {
            t: isCollapsed ? 'Show child pages' : 'Hide child pages',
            c: isCollapsed ? 'i-angle-down' : 'i-angle-up'
        };
    }

    // Document ready
    $(function () {
        var longTouch = true;
        var scrollTop = 0;

        $('.dk-tabnav').each(function () {
            var $ctr = $(this),
                $topUl = $('>ul', $ctr),
                $tabs = $('>li', $topUl),
                mobileJump = $ctr.prev().is('a[name="tabnav"]'),
                $descLinks = mobileJump ? $tabs.not(':first').find('a') : $(null),
                isTouch = null,
                $parents = $tabs.filter(function () {
                    return $(this).find('ul').length > 0;
                });

            function isInNav($elt) {
                return $elt.closest($ctr).length > 0;
            }

            function isInTab($elt, $tab) {
                return $elt.closest($tab).length > 0;
            }

            function adjustDropNavAlignment() {
                let navWidth = $ctr.width();
                $tabs.each(function () {
                    let $tab = $(this),
                        $ul = $('ul', $tab);
                    if (!$ul.length) return;
                    let ulWidth = $ul.width(),
                        tabLeft = $tab.position().left,
                        needsRightAlign = (navWidth - tabLeft) < ulWidth;
                    $ul.toggleClass('tn-align-right', needsRightAlign);
                });
            }

            $(win).on('dk:widthChanged', function (_, ea) {
                var isTch = ea.isMobile || ea.isTablet;
                if (isTouch !== isTch) {
                    isTouch == isTch;
                    $descLinks.prop('hash', isTch ? 'tabnav' : '');
                }
                if (!isTch) adjustDropNavAlignment();
            });

            $('.tn-drop-button', $ctr).attr({
                'aria-haspopup': true,
                'aria-controls': dk.ensureId($topUl, 'tn_'),
                'aria-expanded': null
            });

            if ($parents.length > 0) {
                $topUl.addClass('tn-expandable');
                $parents.each(function () {
                    var $li = $(this),
                        $item = $('>:first', $li),
                        ulId = dk.ensureId($('>ul', $li), 'tn_'),
                        isDiv = $item.is('div'),
                        isCurrent = $li.is('.tn-current'),
                        isDefaultExpanded = $li.is('.tn-default-expanded'),
                        isCollapsed = !isCurrent && !isDefaultExpanded,
                        btnState = getButtonState($item, isCollapsed),
                        $button = (function () {
                            var $btn = isDiv ? $('button', $item) : null;
                            return $btn && $btn.length ? $btn.first() : $('<button type="button"/>');
                        })().text(btnState.t).removeClass().addClass(btnState.c).attr({
                            'aria-controls': ulId,
                            'aria-expanded': isCollapsed ? null : true
                        }),
                        $div = isDiv ? $item : $('<div/>').append($item, $button);
                    ($item.is('span') ? $item : $('span', $item)).attr('tabindex', 0);
                    $li.prepend($div);
                    if (isCollapsed) {
                        $li.addClass(classCollapsed);
                    }
                    $button.on('click', function (e) {
                        var $btn = $(this),
                            $l = $btn.parents('li:first'),
                            $ul = $('>ul', $l),
                            isColl = $l.hasClass(classCollapsed);
                        e.preventDefault();
                        $ul[isColl ? 'slideDown' : 'slideUp'](300, function () {
                            var isC = $li.toggleClass(classCollapsed).hasClass(classCollapsed),
                                btnState = getButtonState($item, isC);
                            $btn.text(btnState.t).removeClass().addClass(btnState.c).attr({
                                'aria-expanded': isC ? null : true
                            });
                            $(this).attr('style', '');
                        });
                        return false;
                    });
                });
            }

            // Contextual drop-down menu for mobile
            $('.tn-drop-button', $ctr).on('click', function () {
                var $btn = $(this),
                    isOpen = $ctr.hasClass(classMenuOpen);
                $topUl[isOpen ? 'fadeOut' : 'fadeIn'](200, function () {
                    $ctr[isOpen ? 'removeClass' : 'addClass'](classMenuOpen);
                    $(this).attr('style', '');
                    $btn.attr('aria-expanded', isOpen ? null : true);
                });
            });

            // Keyboard access for dropdow menus
            $('a,[tabindex=0]', $ctr).on('focus', function () {
                var $active = $(this);
                //$ctr.addClass('no-transition');
                $tabs.each(function () {
                    var $tab = $(this);
                    $tab[isInTab($active, $tab) ? 'addClass' : 'removeClass']('focus');
                });
            }).on('blur', function () {
                window.setTimeout(function () {
                    if (!isInNav($(document.activeElement))) {
                        $('.focus', $ctr).removeClass('focus');
                        // window.setTimeout(function () {
                        //     $ctr.removeClass('no-transition');
                        // }, 10);
                    }
                }, 10);
            });

            // Account for combined use of they keyboard and mouse on the mega menu
            $ctr.on('mouseover', function () {
                var $active = $(document.activeElement);
                if (isInNav($active)) {
                    window.setTimeout(function () {
                        $active.blur();
                    }, 10);
                }
            }).addClass('tn-ready');

        });

        $('nav.dk-tabnav').on('touchstart', function (e) {
            if ($(this).hasClass(classMenuOpen)) {
                e.stopPropagation();
            }
        });

        /* checks if touch is shorter than 200ms and window has scrolled less than 3 pixels */
        $('.site-wrap').on('touchstart', function () {
            setTimeout(function () { longTouch = true; }, 200);
            longTouch = false;
            scrollTop = $(window).scrollTop();
        });

        $('.site-wrap').on('click touchend', function () {
            if ((longTouch === false) && (Math.abs($(window).scrollTop() - scrollTop) < 3)) {
                $('.dk-tabnav').removeClass(classMenuOpen);
            }
            longTouch = true;
        });

        $('.tn-drop-button').on('click touchstart touchend', function (e) {
            e.stopPropagation();
        });

        $('button[name="options"], button.mobile-site-search, button.dk-nav-menu').on('click', function () {
            $('.dk-tabnav').removeClass(classMenuOpen);
        });
    });
})(window.jQuery, window);
