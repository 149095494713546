window.jQuery(function ($) {
    'use strict';
    
    // Faceted search result click tracking
    $('form.fs-form[data-result-click-goal][data-goal-trigger]').each(function () {
        var $form = $(this),
            url = $form.attr('data-goal-trigger'),
            id = $form.attr('data-result-click-goal');
        if (url && id) {
            $form.on('click', '.fs-results .result-items a', function () {
                $.post(url, { goalId: id });
            });
        }
    });
});