(function ($) {
    'use strict';

    var $ctr = $('.dk-language-dropdown'),
        $btnOptions = $('button[name=options]', $ctr),
        $language = $('.language:first', $ctr),
        $menu = $('ul:first', $ctr),
        $menuitems = $('li', $menu),
        attrSel = 'aria-selected';

    function toggleMenu(show) {
        show = typeof show === 'boolean' ? show : !$menu.hasClass('shown');
        $menu[show ? 'addClass' : 'removeClass']('shown')
            .attr('aria-hidden', (!show).toString());
    }

    $btnOptions.on('click', function (e) {
        toggleMenu();
        e.preventDefault();
        return false;
    }).on('blur', function () {
        toggleMenu(false);
    });

    $(document).on('keydown', function (e) {
        var w = e.which,
            $active = $(document.activeElement),
            $target, $first, $last, $sel, a;
        // 13: enter, 27: escape, 32: space, 38: up, 40: down
        if ($active.is($btnOptions)) {
            if ($.inArray(w, [13, 27, 32, 38, 40]) > -1) {
                e.preventDefault();
                if ($menu.hasClass('shown')) {
                    $first = $menuitems.filter(':first');
                    $last = $menuitems.filter(':last');
                    $sel = $menuitems.filter('[' + attrSel + ']:first');
                    switch (w) {
                        case 13:
                        case 32:
                            a = $sel.children('a')[0];
                            if (a && a.click) {
                                a.click();
                            }
                            break;
                        case 27:
                            toggleMenu(false);
                            $btnOptions.trigger('focus');
                            break;
                        case 38:
                        case 40:
                            if (w === 38 && $sel.is($first)) {
                                $target = $last;
                            } else if (w === 40 && $sel.is($last)) {
                                $target = $first;
                            } else {
                                $target = $sel[w === 38 ? 'prev' : 'next']();
                            }
                            if ($target.length < 1) {
                                $target = $first;
                            }
                            $target.attr(attrSel, true).siblings()
                                .removeAttr(attrSel);
                            break;
                    }
                } else {
                    toggleMenu(w === 40 || (w === 27 ? false : ''), true);
                }
                return false;
            }
        } else if (w === 13 && $active.is($language)) {
            e.preventDefault();
            return false;
        }
    });

    $menuitems.on('mouseover', function () {
        $(this).attr(attrSel, true).siblings().removeAttr(attrSel);
    });

})(window.jQuery);
