(function ($) {
    'use strict';

    if (!window.dk.isEditing) {
        $(function () {
            var $otps = $('.on-this-page'),
                hsh = (location.hash || '').match(/^#?(.*)/)[1],
                otpCount = $otps.length || 0,
                $scrollTarget;

            $otps.each(function (io) {
                var $otp = $(this).empty(),
                    $firstArticle = $otp.parents('article:first'),
                    $scope = $firstArticle.length ? $firstArticle : $otp.parents('.dk-content:first'),
                    $list = $('<ul/>'),
                    target = $otp.attr('data-target') || 'h2',
                    $targets = $(target, $scope),
                    count = $targets.length,
                    allowSingle = $otp.is('[data-allow-single]');

                function getId($elt, i) {
                    var id;
                    if ($elt.is('a[name]')) {
                        id = $elt.attr('name');
                    } else {
                        id = $elt.attr('id');
                    }
                    if (!id) {
                        id = 'section' + (otpCount > 1 ? ((io + 1) + '-') : (i + 1));
                        $elt.attr('id', id);
                    }
                    return id;
                }

                if (count > 1 || allowSingle && count > 0) {
                    $targets.each(function (i) {
                        var $me = $(this),
                            id = getId($me, i),
                            $elt = $me.clone();
                        if (!$scrollTarget && hsh === id) {
                            $scrollTarget = $me;
                        }
                        $elt.find('a').each(function () {
                            var $a = $(this);
                            $a.replaceWith($a.html());
                        });
                        $list.append(
                            $('<li><a href="#' + id + '">' + $elt.html() + '</a></li>')
                        );
                    });
                    $otp.append($list);
                } else {
                    $otp.remove();
                }
            });

            if ($scrollTarget) {
                $(window).scrollTop($scrollTarget.offset().top);
            }
        });
    }
})(window.jQuery);
