(function ($) {
    'use strict';

    $(function () {
        var $buttons = $('.audio-pill'),
            textLabels = {
                en: { stopped: 'Listen Now', playing: 'Playing', paused: 'Paused', loading: 'Loading…', error: 'Error' },
                es: { stopped: 'Escuchar ahora', playing: 'Sonando', paused: 'Pausado', loading: 'Cargando…', error: 'Error' }
            };

        function getText($button) {
            var lang = ($button.attr('data-lang') || 'en').trim(),
                set = textLabels[lang] || textLabels.en,
                custom = ($button.attr('data-labels') || '').trim(),
                arr;
            if (custom) {
                arr = custom.split(/[|;]/) || [],
                set = {
                    stopped: arr[0] || set.stopped,
                    playing: arr[1] || set.playing,
                    paused: arr[2] || set.paused,
                    loading: arr[3] || set.loading,
                    error: arr[4] || set.error
                };
            }
            return set;
        }

        function getTimeDisplay(seconds) {
            var day = Math.floor(seconds / 86400),
                hrs = Math.floor(seconds / 3600 % 60 % 24),
                min = Math.floor(seconds / 60 % 60),
                sec = Math.floor(seconds % 60),
                str;
            hrs = hrs < 10 ? '0' + hrs : '' + hrs;
            min = min < 10 ? '0' + min : '' + min;
            sec = sec < 10 ? '0' + sec : '' + sec;
            str = day + '.' + hrs + ':' + min + ':' + sec;
            return str.replace(/^[0.:]{1,6}/, '');
        }

        function setTimes($button, duration, elapsed) {
            var str = '',
                showElapsed = $button.is('.playing,.paused');
            if ($.isNumeric(duration)) {
                if (showElapsed && $.isNumeric(elapsed)) {
                    str += getTimeDisplay(elapsed) + '/';
                }
                str += getTimeDisplay(duration);
            }
            $('.times', $button).text(str);
        }

        function setState($button, className) {
            var aud = $button.find('audio')[0] || {},
                text = getText($button);
            $button.removeClass(Object.keys(text)).addClass(className)
                .find('.label').text(text[className]);
            if ($button.is['[data-counter]']) {
                setTimes($button, aud.duration, aud.currentTime);
            }
        }

        function sendAnalyticsEvent($button, status) {
            try {
                var src = $('audio:first', $button).prop('src');
                (window.dataLayer = window.dataLayer || []).push({
                    event: 'audio',
                    audio: {
                        status: status,
                        url: src,
                        title: (function () {
                            try {
                                // Attempt to get the clip/page title, or fall back to the file name
                                return ($button.parents('.sc-card,.dk-content').first().find('.sc-card-header,h1').first().text() || '').trim() ||
                                    (new window.URL(src, location.href)).pathname.split('/').slice(-1)[0] || '';
                            } catch(_) { return ''; }
                        })()
                    }
                });
            } catch(_) { /* ignore */ }
        }

        function stop($button, restart) {
            $('audio:first', $button).each(function () {
                this.pause();
                if (restart) {
                    this.currentTime = 0;
                }
            });
            if (restart) {
                $('.progress > div', $button).css('width', 0);
            }
            setState($button, restart ? 'stopped' : 'paused');
        }

        function stopAll() {
            $buttons.each(function () {
                stop($(this), true);
            });
        }

        function play($button) {
            if (!$button.hasClass('paused')) {
                stopAll();
                setState($button, 'loading');
            }
            $('audio:first', $button).each(function () {
                if (this.currentTime === 0) sendAnalyticsEvent($button, 'start');
                this.play();
            });
        }

        $buttons.each(function () {
            var $button = $(this),
                btnSrc = $button.attr($button.is('a') ? 'href' : 'data-src'),
                $audio = $('audio:first', $button),
                $progress = $('<div class="progress"><div></div></div>'),
                text = getText($button),
                keepDefaultText = $button.is('[data-default-text]'),
                txt = keepDefaultText ? $button.text() : text.stopped,
                supportsAudio = !!document.createElement('audio').canPlayType,
                showCounter = $button.is('[data-counter]');
            $audio = $audio.length > 0 ? $audio : $button.next('audio');
            if ($audio.length < 1 && btnSrc) {
                $audio = $('<audio/>').attr({ 'src': btnSrc, 'preload': showCounter ? 'metadata' : 'none' });
            }
            if (supportsAudio) {
                $button.attr('role', 'button').empty().append(
                    $('<span class="label"/>').text(txt),
                    showCounter ? $('<span class="times"/>') : null,
                    $audio,
                    $progress
                ).on('click', function (e) {
                    var $btn = $(this),
                        isPlaying = $btn.hasClass('playing');
                    e.preventDefault();
                    if (!isPlaying) {
                        play($btn);
                    } else {
                        stop($btn);
                    }
                    return false;
                });
                $audio.on('playing', function () {
                    setState($button, 'playing');
                }).on('paused', function () {
                    setState($button, 'paused');
                }).on('ended', function () {
                    setState($button, 'stopped');
                    sendAnalyticsEvent($button, 'complete');
                }).on('error', function () {
                    setState($button, 'error');
                }).on('timeupdate', function () {
                    $('div', $progress).css('width', ((this.currentTime / this.duration) * 100) + '%');
                    if (showCounter) {
                        setTimes($button, this.duration, this.currentTime);
                    }
                }).on('loadedmetadata', function () {
                    if (showCounter) {
                        setTimes($button, this.duration, this.currentTime);
                    }
                });
            } else {
                btnSrc = $audio.attr('src') || $('source:first', $audio).attr('src');
                $button.replaceWith($('<a class="audio-pill" href="' + btnSrc + '"/>').text(text.stopped));
                $audio.remove();
            }
        });
    });
})(window.jQuery);
