(function (win, $) {
    'use strict';

    var dk = win.dk,
        $win = $(win),
        stickyDefaults = {
            ft: 65, // fixedTop
            cmt: 65, // containerMinTop
            cbmo: 76, // containerBottomMinOffset
            cbp: 14 // containerBottomPad
        },
        fixedTop = stickyDefaults.ft, // The top position when the panel is sticky (fixed)
        containerMinTop = stickyDefaults.cmt, // The minimum top offset for .fs-body before the summary becomes sticky
        containerBottomMinOffset = stickyDefaults.cbmo, // The minimum offset for the bottom of .fs-body (less the height of the panel box) before the panel reattaches
        containerBottomPad = stickyDefaults.cbp; // The distance from the bottom of .fs-body at which the panel will reattach

    function updateStickyVals(isTablet) {
        function val(v) {
            return isTablet ? dk.toTabletPx(v) : v;
        }
        fixedTop = val(stickyDefaults.ft);
        containerMinTop = val(stickyDefaults.cmt);
        containerBottomMinOffset = val(stickyDefaults.cbmo);
        containerBottomPad = val(stickyDefaults.cbp);
    }

    $(function () {
        var statusTimeoutLength = 500, // Time to wait before updating AJAX status
            $form, $facets, formMethod, formAction, xhr, statusTimeout, submitForm,
            pageTitle = $('title').text() || 'NIDDK',
            defaults = (function () {
                var arr = [];
                $('.fs-form input[data-default][name]').each(function () {
                    var $ipt = $(this),
                        dflt = $ipt.attr('data-default');
                    arr.push([
                        $ipt.attr('name'),
                        (!dflt && $ipt.is('[value]')) ? $ipt.val() : dflt
                    ].join('='));
                });
                $('.fs-form select[name]').each(function () {
                    var $sel = $(this),
                        val = $sel.attr('data-default') || $('option[data-default]:first', $sel).val();
                    if (val) {
                        arr.push([$sel.attr('name'), val].join('='));
                    }
                });
                if ($('.fs-form button[name="p"]').length > 0) {
                    arr.push('p=1');
                }
                return arr;
            })(),
            getInputElementNames = function (type) {
                var arr = [];
                $('.fs-form :' + type + '[name]').each(function () {
                    var name = $(this).attr('name');
                    if (arr.indexOf(name) < 0) {
                        arr.push(name);
                    }
                });
                return arr;
            },
            checkboxNames = getInputElementNames('checkbox'),
            radioNames = getInputElementNames('radio'),
            $ctr = $('.fs-body:first'),
            $summary = $('.fs-results .summary:first'), // need to update when results change
            resultListTopMargin = Math.max(parseFloat($summary.css('margin-bottom')), parseFloat($summary.next().css('margin-top'))),
            ariaChecked = 'aria-checked',
            otherParams = [];

        function resetSummaryLocation() {
            var summaryBox = $summary[0].getBoundingClientRect(),
                ctrBox = $ctr[0].getBoundingClientRect(),
                listTopMargin = summaryBox.height + resultListTopMargin;
            if (ctrBox.top <= containerMinTop && (ctrBox.bottom >= containerBottomMinOffset + summaryBox.height)) {
                $summary.addClass('stuck').css({ 'position': 'fixed', 'top': fixedTop, 'left': summaryBox.left, 'right': 'auto', 'cursor': '' })
                    .next().css('margin-top', listTopMargin);
            } else if (ctrBox.bottom < containerBottomMinOffset + summaryBox.height) {
                $summary.removeClass('stuck')
                    .css({ 'position': 'absolute', 'top': ctrBox.height - summaryBox.height - containerBottomPad, 'left': '', 'right': '', 'cursor': 'pointer' })
                    .next().css('margin-top', listTopMargin);
            } else {
                $summary.removeClass('stuck').css({ 'position': '', 'top': '', 'left': '', 'right': '', 'cursor': '' })
                    .next().css('margin-top', '');
            }
        }

        function updateFilterCounts() {
            var map = {},
                $inputs = $('.filters input'),
                summaryFilters = $('.fs-results .summary[data-filters]:first').attr('data-filters');
            function updateMap(filters) {
                var cats = (filters || '').split(/[& ]/);
                cats.forEach(function (v) {
                    var spl = v.split('='),
                        cat = spl[0],
                        vals = (spl[1] || '').split(/[,]/);
                    if (Object.prototype.hasOwnProperty.call(map, cat)) {
                        vals.forEach(function (v2) {
                            var sp = (v2 || '').split(':'),
                                vv = decodeURIComponent((sp[0] || '').replace(/\+/g, ' ')),
                                ct = sp[1] ? parseInt(sp[1], 10) : 1;
                            if (Object.prototype.hasOwnProperty.call(map[cat], vv)) {
                                map[cat][vv] += ct;
                            }
                        });
                    }
                });
            }
            $inputs.each(function () {
                var $ipt = $(this),
                    name = $ipt.attr('name'),
                    val = $ipt.attr('value');
                map[name] = map[name] || {};
                map[name][val] = map[name][val] || 0;
            });
            if (summaryFilters) {
                updateMap(summaryFilters);
            } else {
                $('.fs-results .result-items li[data-filters]').each(function () {
                    updateMap($(this).attr('data-filters'));
                });
            }
            $inputs.each(function () {
                var $ipt = $(this),
                    isChecked = $ipt.prop('checked'),
                    $lbl = $ipt.next('label'),
                    $count = $lbl.find('.count'),
                    name = $ipt.attr('name'),
                    val = $ipt.attr('value');
                $ipt.data('count', map[name][val]);
                if ($ipt.parents('.sub-opts:first').length) {
                    return true; // continue
                }
                if (isChecked) {
                    if ($count.length < 1) {
                        $count = $('<span class="count"/>');
                        $lbl.append(' ', $count);
                    }
                    $count.text(map[name][val]);
                } else {
                    $count.remove();
                }
            });
            $('.sub-opts', $form).each(function () {
                var $me = $(this),
                    $lbl = $me.children('label,.label'),
                    $count = $lbl.find('.count'),
                    $ipts = $('input', $me).filter(function () { return $(this).prop('checked'); }),
                    count = 0;
                if ($ipts.length) {
                    if ($count.length < 1) {
                        $count = $('<span class="count"/>');
                        $lbl.append(' ', $count);
                    }
                    $ipts.each(function () { count += $(this).data('count'); });
                    $count.text(count);
                } else {
                    $count.remove();
                }
            });
            $('.fs-mobile-tools .summary').empty().append(
                $('<strong class="count"/>').html($('.fs-results .summary .count').html()),
                ' results'
            );
        }

        function updateActiveFilterCountAndSortSelection() {
            var $mobileFilters = $('.fs-mobile-tools .filters'),
                $sortPanel = $('.fs-facets .sort'),
                $parent = $($mobileFilters.attr('data-panel-selector')).find('.filters').first(),
                checks = $(':checkbox:checked', $parent).length,
                radio = $(':radio:checked:not([data-default])', $parent).length;
            $('.count', $mobileFilters).text(checks + radio);
            $('.panel-title span', $sortPanel).replaceWith(
                $('<span/>').html($('input:checked', $sortPanel).siblings('label:first').html())
            );
        }

        function setUpdating() {
            $('.fs-results,.fs-mobile-tools').find('.summary')
                .html('<em>Updating results...</em>');
        }

        function setError() {
            $('.fs-results .summary').empty().append(
                '<i class="i-exclamation-circle i-2x"></i>',
                ' We were unable to update the results. Please ',
                $('<a href="javascript:void(0)"/>').text('reload the page').on('click', function (e) {
                    e.preventDefault();
                    location.reload();
                    return false;
                }),
                ' to try again.'
            );
            $('.fs-mobile-tools .summary').empty().append(
                '<i class="i-exclamation-circle"></i> ',
                '<em>Unable to update</em><br />',
                $('<a href="javascript:void(0)"/>').text('Reload the page').on('click', function (e) {
                    e.preventDefault();
                    location.reload();
                    return false;
                })
            );
        }

        function getQueryParamDefault(name) {
            return defaults
                .map(p => p.split('='))
                .filter(s => name === s[0])
                .map(s => s[1]);
        }

        function isQueryParamDefault(name, query) {
            var qv = dk.query.getValue(name, query),
                dv = getQueryParamDefault(name),
                count = 0;
            qv.forEach(function (val) {
                count += dv.indexOf(val) >= 0 ? 1 : 0;
            });
            return count == qv.length;
        }

        function checkConditionalOption(name, val, query) {
            var $condRdo = $(':radio[name="' + name + '"][value="' + val + '"][data-require-val]', $form);
            if ($condRdo.length > 0) {
                return !isQueryParamDefault($condRdo.attr('data-require-val'), query);
            }
            return true;
        }

        function updateUrl(resultHtml, query, replaceState) {
            var arr = [], queryPairs = [],
                path = location.pathname;
            path = path.replace(/\/$/, '');
            query = query || '';
            query.split('&').forEach(function (param) {
                if (param) {
                    queryPairs.push(param);
                    if (defaults.indexOf(param) < 0) {
                        arr.push(param);
                    }
                }
            });
            defaults.forEach(function (pair) {
                var name = (pair || '').split('=')[0] || '';
                if (queryPairs.indexOf(pair) < 0 && checkboxNames.indexOf(name) >= 0) {
                    arr.push(pair.replace('=', '=-'));
                }
            });
            if (replaceState && otherParams) {
                arr = arr.concat(otherParams);
            }
            arr = arr.filter(function (v, i, a) { return a.indexOf(v) === i; });
            query = arr.join('&');
            win.history[replaceState ? 'replaceState' : 'pushState'](
                { resultHtml: resultHtml, query: query },
                pageTitle,
                path + (query ? ('?' + query) : '')
            );
        }

        // Load form values from query string
        function loadFormValues(query) {
            var queryPairs = [], arr = [];
            $('.fs-facets input:checkbox').prop('checked', false)
                .parents('label:first').attr(ariaChecked, 'false');
            query = query || ((location.search || '').match(/^\??(.+)/) || [])[1] || '';
            query.split('&').forEach(function (pair) {
                var name;
                if (pair) {
                    name = (pair || '').split('=')[0] || '';
                    queryPairs.push(pair);
                    if (pair.indexOf('=-') < 0 || (pair.indexOf('=-') < 0 && checkboxNames.indexOf(name) < 0)) {
                        arr.push(pair);
                    }
                }
            });
            defaults.forEach(function (pair) {
                var neg = pair.replace('=', '=-'),
                    name = (pair || '').split('=')[0] || '';
                if (queryPairs.indexOf(pair) < 0 && queryPairs.indexOf(neg) < 0 && checkboxNames.indexOf(name) >= 0) {
                    arr.push(pair);
                }
                if (radioNames.indexOf(name) >= 0 && query.toLowerCase().indexOf(name.toLowerCase() + '=') < 0) {
                    arr.push(pair);
                }
            });
            arr.forEach(function (p) {
                var spl = p.split('='),
                    $elt = $('input,select', $form).filter('[name="' + spl[0] + '"]'),
                    val = decodeURIComponent(spl[1] || '').replace(/\+/g, ' ');
                if ($elt.is(':text,[type="hidden"],select')) {
                    $elt.val(val);
                } else if ($elt.is(':checkbox,:radio')) {
                    $elt.filter(':checkbox[value="' + val + '"]').prop('checked', true);
                    $elt.filter(':radio[value="' + val + '"]').prop('checked', true);
                }
            });
            $(':radio[data-require-val]', $form).each(function () {
                var $rdo = $(this),
                    n = $rdo.attr('name'),
                    v = $rdo.val(),
                    dflt = getQueryParamDefault(n)[0] || '',
                    req = $rdo.attr('data-require-val');
                if (req) {
                    if (isQueryParamDefault(req, query)) {
                        // Hide the conditional option and set to default value if the conditional option is selected
                        $rdo.parents('li:first').css('display', 'none');
                        if (dk.query.getValue(n, query).indexOf(v) >= 0) {
                            $(':radio[name="' + n + '"][value="' + dflt + '"]', $form).prop('checked', true);
                        }
                    } else {
                        // Show the conditional option
                        $rdo.parents('li:first').css('display', '');
                    }
                }
            });
            $('.sub-opts').each(function () {
                var $me = $(this),
                    $lbl = $me.children('label,.label').first(),
                    subsChecked = $('input', $me).filter(function () { return $(this).prop('checked'); }).length > 0;
                $lbl[subsChecked ? 'addClass' : 'removeClass']('checked');
            });
        }

        function clearSearch($button, noFocus) {
            var $srch = $('.fs-search input:text').val(''),
                attr = $button && $button.attr ? $button.attr('data-set-value') : '';
            if (!noFocus) {
                $srch.trigger('focus');
            }
            submitForm(attr);
        }

        function clearFilters($button) {
            var $filters = $('.fs-facets .filters:first', $form),
                dataClear = $button ? $button.attr('data-clear') : '',
                names = dataClear ? dataClear.split(' ') : 0;

            function shouldClear() {
                return !names || (names && $.inArray($(this).attr('name'), names) >= 0);
            }

            $(':checkbox', $filters).filter(shouldClear).prop('checked', false)
                .parents('label').attr(ariaChecked, 'false');
            $(':checkbox[data-default]', $filters).filter(shouldClear).prop('checked', true)
                .parents('label').attr(ariaChecked, 'true');
            $(':radio[data-default]', $filters).filter(shouldClear).prop('checked', true);
            $('input[type="hidden"][data-filter]', $form).filter(shouldClear).each(function () {
                var $hdn = $(this),
                    dflt = $hdn.attr('data-default') || '';
                if ($hdn.is('[data-clear="remove"]')) {
                    $hdn.remove();
                } else if (!$hdn.is('[data-clear="none"]')) {
                    $hdn.val(dflt);
                }
            });
            $('.sub-opts').each(function () {
                var $subOpts = $(this),
                    subsChecked = $('input', $subOpts).filter(function () { return $(this).prop('checked'); }).length > 0;
                $('.label', $subOpts)[subsChecked ? 'addClass' : 'removeClass']('checked');
            });
            submitForm($button.attr('data-set-value'));
        }

        function clearSearchAndFilters($button) {
            clearSearch($button, true);
            clearFilters($button);
        }

        function updateSummaryForDisplay($sum) {
            var $btn = $('<button type="button" class="clear-all" title="Clear search and reset filters to their default values">Clear All</button>'),
                $mainClearBtn = $('.fs-search .search-clear button'),
                mcbAttr = $mainClearBtn.attr('data-set-value');
            if (mcbAttr) {
                $btn.attr('data-set-value', mcbAttr);
            }
            if ($sum.is('[data-filtered]') && !$sum.find('.clear-all').length) {
                $sum.addClass('can-clear')
                    .wrapInner('<div class="line-clamp" data-no-auto-title></div>')
                    .append($btn);
            }
        }

        function populateResults(resultHtml, query) {
            var $doc = $(resultHtml), $resCtr, $docSummary;
            $doc = $doc.is('.fs-results') ? $doc.filter(':first') : $doc.find('.fs-results:first');
            $docSummary = $('.summary:first', $doc);
            if ($summary.hasClass('stuck')) {
                $docSummary.addClass('stuck');
            }
            updateSummaryForDisplay($docSummary);
            dk.evaluateExitLinks($doc);
            dk.evaluateEmailLinks($doc);
            $resCtr = $('.fs-results:first').html($doc.html());
            $summary = $resCtr.find('.summary:first')
                .css({ 'position': '', 'top': '', 'left': '', 'right': '', 'width': $('.fs-results').width() });
            $summary.next().css('margin-top', '');
            dk.resetLineClamp($('.line-clamp', $resCtr));
            resetSummaryLocation();
            if (typeof query === 'string') {
                loadFormValues(query);
            }
            updateActiveFilterCountAndSortSelection();
            updateFilterCounts();
        }

        function serializeForm() {
            const formQuery = $form.serialize().replace(/%20/g, '+'),
                formParams = dk.query.getParams(formQuery),
                defaultParams = dk.query.getParams(defaults.join('&'));
            // Account for checkboxes that might be checked by default but have been
            // unchecked by the user
            Object.keys(defaultParams)
                .filter(k => checkboxNames.indexOf(k) >= 0) // Only consider checkboxes
                .forEach(k => {
                    const arr = (formParams[k] || []);
                    defaultParams[k]
                        .filter(v => arr.indexOf(v) < 0 && arr.indexOf('-' + v) < 0) // Limit to values not already included
                        .forEach(v => arr.push('-' + v)); // Add a negation value
                    formParams[k] = arr; // Add to form parameters
                });
            return Object.keys(formParams) // Convert form params object back into query string
                .map(k => {
                    const val = formParams[k];
                    if (Array.isArray(val)) return val.map(v => k + '=' + v);
                    return val;
                }) // Create key/value pairs
                .reduce((acc, arr) => acc.concat(arr), []) // Flatten array
                .join('&'); // Join with ampersand
        }

        submitForm = function (arg, append) {
            var query = serializeForm();
            function clearTo() {
                win.clearTimeout(statusTimeout);
                statusTimeout = null;
            }
            if (xhr) {
                xhr.abort();
            }
            if (statusTimeout) {
                clearTo();
            }
            statusTimeout = win.setTimeout(function () {
                setUpdating();
            }, statusTimeoutLength);
            if (arg) {
                arg = $.isArray(arg) ? arg : [arg && typeof arg === 'string' ? arg : ''];
                arg.forEach(function (val) {
                    var spl, cmb;
                    if (val && typeof val === 'string') {
                        spl = val.split('=');
                        if (spl[0]) {
                            spl[1] = typeof spl[1] === 'string' ? spl[1] : getQueryParamDefault(spl[0]).join('&' + spl[0] + '=');
                            if (checkConditionalOption(spl[0], spl[1], query)) {
                                cmb = spl.join('=');
                                if (!append) {
                                    query = query.replace(new RegExp(spl[0] + '=[^&]*'), '');
                                }
                                query = [query, cmb].join('&');
                                loadFormValues(query);
                            }
                        }
                    }
                });
            }
            xhr = $.ajax({
                type: formMethod,
                url: formAction,
                data: query,
                dataType: 'html',
                success: function (data) {
                    xhr = null;
                    clearTo();
                    populateResults(data);
                    updateUrl(data, query);
                },
                error: function (_, status) {
                    xhr = null;
                    clearTo();
                    if (!status.match(/abort/i)) {
                        setError();
                        updateUrl(null, query);
                    }
                }
            });
        };

        // Only execute binding code if this is a faceted search page
        if ($('.pt-facet-search').length === 0) {
            return;
        }

        // Determine form action
        $form = $('form.fs-form:first');
        $facets = $('.fs-facets');
        formMethod = $form.attr('method') || 'get';
        formAction = $form.attr('data-action') || $form.attr('action') || location.pathname;
        loadFormValues();

        otherParams = dk.query.getDistinctPairsByName(serializeForm());

        // Set up mobile search and filter buttons
        $('.fs-mobile-tools').each(function () {
            var $toolbar = $(this);
            $('button', $toolbar).each(function () {
                var $button = $(this),
                    $panel = $($button.parent().attr('data-panel-selector')).attr('tabindex', -1),
                    panelId = dk.ensureId($panel);
                $button.attr('aria-controls', panelId).on('click', function (e) {
                    e.preventDefault();
                    if ($('.sort,.filters', $panel).length > 0) {
                        $('.expand-title', $panel).each(function () {
                            var $title = $(this).removeClass('opened'),
                                $btn = $('button', $title).removeAttr('aria-expanded');
                            $btn.text(($btn.text() || '').replace('collapse', 'expand'))
                                .attr('title', ($btn.attr('title') || '').replace('collapse', 'expand'));
                            $title.next('.options').hide();
                        });
                    }
                    $button.attr('aria-expanded', 'true');
                    $panel.addClass('shown');
                    win.setTimeout(() => { $panel.trigger('focus'); }, 300);
                    $('.site-wrap').addClass('popup-overlay');
                    $('.page-mask').on('click.closePop', function (ev) {
                        ev.preventDefault();
                        $panel.removeClass('shown');
                        $button.removeAttr('aria-expanded');
                        $('.site-wrap').removeClass('popup-overlay');
                        $(this).off('click.closePop');
                        return false;
                    });
                    return false;
                });
            });
        });

        // Set up sort/filter navigation
        $facets.find('.expand-title').each(function () {
            var ulId = dk.generateId(),
                $title = $(this),
                titleText = $title.text(),
                $titleSpan = $('<span/>').text(titleText),
                titleSpanId = dk.ensureId($titleSpan),
                isSort = $title.parents('.sort').length > 0,
                isHidden = !$title.hasClass('opened'),
                $expand = $('<button type="button"/>').text('(' + (isHidden ? 'expand' : 'collapse') + ' options)').attr({
                    'aria-controls': ulId,
                    'role': 'button',
                    'aria-expanded': (isHidden ? null : 'true'),
                    'title': 'Click to ' + (isHidden ? 'expand ' : 'collapse ') + (isSort ? 'sorting' : (titleText + ' filter')) + ' options'
                }).on('click', function (e) {
                    var isOpening = !$title.hasClass('opened'),
                        $ul = $title.next('ul')[isOpening ? 'slideDown' : 'slideUp'](function () {
                            $title.toggleClass('opened', isOpening);
                        });
                    e.preventDefault();
                    $expand.attr({ 'aria-expanded': (isOpening ? 'true' : null) });
                    if (isOpening) {
                        $expand.text(($expand.text() || '').replace('expand', 'collapse'))
                            .attr('title', ($expand.attr('title') || '').replace('expand', 'collapse'));
                        $ul.trigger('focus');
                        if (dk.responsive.isMobile) {
                            $('.sort ul,.filters ul', $facets).not($ul).slideUp();
                            $('.expand-title', $facets).not($title).removeClass('opened')
                                .find('button').each(function () {
                                    var $btn = $(this).removeAttr('aria-expanded');
                                    $btn.text(($btn.text() || '').replace('expand', 'collapse'))
                                        .attr('title', ($btn.attr('title') || '').replace('expand', 'collapse'));
                                });
                        }
                    } else {
                        $expand.text(($expand.text() || '').replace('collapse', 'expand'))
                            .attr('title', ($expand.attr('title') || '').replace('collapse', 'expand'));
                    }
                    return false;
                }).on('keydown', function (e) {
                    if (e.which === 32 && $(document.activeElement).is(this)) {
                        e.preventDefault();
                        $(this).trigger('click');
                    }
                }),
                $ul = $title.next('ul').attr({
                    'id': ulId,
                    'tabindex': -1
                }),
                $labels = $('label', $ul),
                labelIds = [];
            if (isSort) {
                $title.append($expand).attr('aria-label', 'Sort by');
            } else {
                $title.empty().append($titleSpan, ' ', $expand).attr('aria-labelledby', titleSpanId);
            }
            $labels.each(function () {
                var $lbl = $(this),
                    lid = dk.generateId('lbl_', $lbl),
                    $ipt = $lbl.prev('input'),
                    iid = dk.generateId('ipt_', $ipt),
                    lblObj = { 'id': lid };
                lblObj.for = iid;
                $lbl.attr(lblObj).on('click', function () {
                    updateActiveFilterCountAndSortSelection();
                });
                $ipt.attr('id', iid).on('change', function () {
                    updateActiveFilterCountAndSortSelection();
                });
                labelIds.push(lid);
            }).on('keydown', function (e) {
                if (e.which === 32 && $(document.activeElement).is(this)) {
                    e.preventDefault();
                    $('input', this).trigger('click');
                }
            });
            $ul.find('input').each(function () {
                var $ipt = $(this);
                $ipt.parents('label:first').attr(ariaChecked, $ipt.is(':checked') ? 'true' : 'false');
            });
            if (isHidden) {
                $ul.hide();
            }
        });

        // Override form submission
        $form.on('submit', function (e) {
            e.preventDefault();
            submitForm();
            return false;
        });

        // Search button
        $('.fs-search .search-box button').on('click', function (e) {
            e.preventDefault();
            $('.page-mask').trigger('click.closePop');
            submitForm($(this).attr('data-set-value'));
            return false;
        });

        // Clear Search button
        $('.fs-search .search-clear button').on('click', function (e) {
            e.preventDefault();
            clearSearch($(this));
            return false;
        });

        // Clear All (filters) button
        $form.on('click', '.fs-facets .filters button, .fs-clear-filters', function (e) {
            e.preventDefault();
            clearFilters($(this));
            return false;
        });

        // Facet options
        $('.fs-facets input').on('change', function () {
            var $ipt = $(this),
                $subOpts = $ipt.parents('.options:first').find('.sub-opts:first'),
                subsChecked;
            if ($subOpts.length) {
                subsChecked = $('input', $subOpts).filter(function () { return $(this).prop('checked'); }).length > 0;
                $('.label', $subOpts)[subsChecked ? 'addClass' : 'removeClass']('checked');
            }
            submitForm();
        });

        $('.fs-facets .sub-opts .label.radio').on('click', function () {
            $('.tic-tacs :radio', $(this).parents('.sub-opts:first')).first().trigger('click');
        });

        // Items per page drop-down
        $form.on('change', '.dk-per-page select', function (e) {
            e.preventDefault();
            submitForm();
            return false;
        });

        // Pagination buttons
        $form.on('click', '.dk-pages button', function (e) {
            var $btn = $(this);
            e.preventDefault();
            $('input[type="hidden"][name="p"]').remove();
            submitForm($btn.attr('name') + '=' + $btn.val(), true);
            $('html, body').animate({
                scrollTop: $ctr.offset().top - fixedTop - 1
            }, 1000);
            return false;
        });

        updateSummaryForDisplay($summary);
        dk.resetLineClamp($('.line-clamp', $summary));

        // Click event for sticky summary
        $ctr.on('click', '.summary', function () {
            var $sum = $(this);
            if ($sum.is('.stuck') || $sum.css('position') === 'absolute') {
                $('html, body').animate({
                    scrollTop: $ctr.offset().top - fixedTop - 1
                }, 1000);
            }
        }).on('click', '.summary .clear-all', function (e) {
            e.preventDefault();
            clearSearchAndFilters($(this));
        });

        $win.on('dk:widthChanged', function (_, ea) {
            updateStickyVals(ea.isTablet);
            $summary.css({ 'position': '', 'top': '', 'left': '', 'right': '', 'width': $('.fs-results').width() });
            $summary.next().css('margin-top', '');
            if (ea.isMobile) {
                $win.off('scroll.resultSummary');
                $ctr.css('position', '');
            } else {
                resetSummaryLocation();
                $win.on('scroll.resultSummary', function () {
                    resetSummaryLocation();
                });
                $ctr.css('position', 'relative');
            }
        }).on('dk:siteLayoutChanged', function (_, isMobile) {
            if (!isMobile) {
                $('.page-mask').trigger('click.closePop');
            }
        });

        updateFilterCounts();
        updateActiveFilterCountAndSortSelection();

        // Restore page number if the page is loaded from the query string
        if (!isQueryParamDefault('p')) {
            $('.dk-pagination .dk-pages').first().append(
                $('<input type="hidden" name="p"/>').val(dk.query.getValue('p'))
            );
        }

        updateUrl(
            $('<div/>').append($('.fs-results:first').clone()).html(),
            serializeForm(),
            true
        );
        win.addEventListener('popstate', function (e) {
            populateResults(e.state.resultHtml, e.state.query);
        });
    });
})(window, window.jQuery);
