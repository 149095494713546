(function (win, $) {
    'use strict';

    // Document ready
    function initializeSiteHeader() {
        var $header = $('.site-header:first'),
            $nav = $('.dk-site-nav', $header),
            $brand = $('.stripe-brand', $header),
            $siteWrap = $('.site-wrap'),
            $pageMask = $('.page-mask'),
            $win = $(win),
            $tabs = $nav.find('.sn-home,.sn-section'),
            $closeMobileNav = $('<button type="button" class="sn-close-nav"><i class="i-times"></i><span class="sr-only">Close navigation</span></button>'),
            transitionTime = getTransitionMilliseconds($nav),
            hideMobileNavButton = win.dk.debounce(() => { win.setTimeout(() => {
                $closeMobileNav.attr('aria-hidden', true).hide();
            }, 500); }, 600, true),
            isPushed;

        function isInNav($elt) {
            return $elt.closest($nav).length > 0;
        }

        function isInTab($elt, $tab) {
            return $elt.closest($tab).length > 0;
        }

        function getTransitionMilliseconds($element) {
            var o = {}, t, $elt = $element || $nav,
                props = $elt.css('transition-property').split(/,\s+/),
                durs = $elt.css('transition-duration').split(/,\s+/);
            props.forEach(a => o[a] = durs[1]);
            t = o['background-position-y'] || '0.25s';
            return parseFloat(t) * (t.match(/^(?:\d+\.?|\d*\.\d+)s$/i) ? 1000 : 1);
        }

        function unpushMenu(noRefresh) {
            if (isPushed === false) return;
            isPushed = false;
            $siteWrap.removeClass('pushed');
            win.dk.setBodyScrollLock(false);
            $pageMask.off('click');
            hideMobileNavButton();

            if (noRefresh) return;
            win.setTimeout(function () {
                win.dk.refreshSiteLayout();
            }, 500);
        }

        function pushMenu() {
            if (isPushed === true) return;
            isPushed = true;
            $pageMask.on('click', function (e) {
                e.preventDefault();
                unpushMenu();
            });
            $closeMobileNav.removeAttr('aria-hidden').show();
            $siteWrap.addClass('pushed');
            win.dk.setBodyScrollLock(true);
        }

        // Sticky header
        if ($nav.is('.sticky')) {
            $win.on('DOMContentLoaded load resize scroll', function () {
                var isNavStuck = $nav.is('.stuck'),
                    isBrandStuck = $brand.is('.stuck'),
                    headerBottom = $header.length ? $header[0].getBoundingClientRect().bottom : 0,
                    navHeight = $nav.length ? $nav[0].getBoundingClientRect().height : 0,
                    navTop = headerBottom ? (headerBottom - navHeight) : 1,
                    brandHeight = $brand.length ? $brand[0].getBoundingClientRect().height : 0,
                    brandTop = headerBottom ? (headerBottom - brandHeight) : 1;
                if (navTop <= 0 && !isNavStuck || navTop > 0 && isNavStuck) {
                    $nav[navTop <= 0 ? 'addClass' : 'removeClass']('stuck').addClass('no-hover-delay');
                    win.setTimeout(() => $nav.removeClass('no-hover-delay'), transitionTime);
                }
                if (brandTop < 0 && !isBrandStuck || brandTop >= 0 && isBrandStuck) {
                    $brand[brandTop < 0 ? 'addClass' : 'removeClass']('stuck');
                }
            });
        }

        $win.on('resize', function () {
            $nav.addClass('no-transition');
            win.setTimeout(function () {
                $nav.removeClass('no-transition');
            }, 300);
        }).on('pageshow', function (e) {
            if (e.originalEvent.persisted) {
                win.location.reload();
            }
        });

        // Keyboard access for mega menu
        $('a', $nav).on('focus', function () {
            var $active = $(this);
            $nav.addClass('no-hover-delay');
            $tabs.each(function () {
                var $tab = $(this);
                $tab[isInTab($active, $tab) ? 'addClass' : 'removeClass']('focused');
            });
        }).on('blur', function () {
            win.setTimeout(function () {
                if (!isInNav($(document.activeElement))) {
                    $('.focused', $nav).removeClass('focused');
                    win.setTimeout(function () {
                        $nav.removeClass('no-hover-delay');
                    }, 10);
                }
            }, 10);
        });

        // Account for combined use of they keyboard and mouse on the mega menu
        $nav.on('mouseover', function () {
            var $active = $(document.activeElement);
            if (isInNav($active)) {
                win.setTimeout(function () {
                    $active.blur();
                }, 10);
            }
        });

        // Hide the mobile nav
        unpushMenu(true);

        hideMobileNavButton();
        $('.page-mask').append($closeMobileNav);

        $('.dk-nav-menu').on('click', function (e) {
            e.preventDefault();
            pushMenu();
            return false;
        });

        if (!$nav.data('init')) {
            $(win).on('dk:siteLayoutChanged', function (_, isMobile) {
                if (!isMobile) unpushMenu(true);
            });

            $nav.data('init', 1);
        }
    }

    // On document load
    $(function () {
        initializeSiteHeader();
    });
})(window, window.jQuery);
