"use strict";
// Legacy GFR Calculator library
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChildCalculator = exports.AdultCalculator = exports.Calculator = exports.Type = exports.Units = void 0;
/**
 * Systems of measurement
 */
var Units;
(function (Units) {
    /**
     * The Conventional system of measurement
     */
    Units[Units["Conventional"] = 0] = "Conventional";
    /**
     * The SI system of measurement
     */
    Units[Units["SI"] = 1] = "SI";
})(Units || (exports.Units = Units = {}));
/**
 * Calculation types for adult GFR values (i.e., the equation to use for calculation)
 */
var Type;
(function (Type) {
    /**
     * Calculation using the Modification of Diet in Renal Disease (MDRD) Study equation
     */
    Type[Type["Mdrd"] = 0] = "Mdrd";
    /**
     * Calculation using the Chronic Kidney Disease Epidemiology Collaboration (CKD-EPI) equation
     */
    Type[Type["CkdEpi"] = 1] = "CkdEpi";
})(Type || (exports.Type = Type = {}));
var Calculator = /** @class */ (function () {
    /**
     * Constructs a new abstract glomerular filtration rate (GFR) calculator instance
     * @class Gfr.Calculator
     * @classdesc Abstract base class for a glomerular filtration rate (GFR) calculator
     * @abstract
     * @param {Gfr.Units} units The system used for units of measure
     * @param {number} maxVal The maximum value for the result; set to <= 0 to disable maximum value; default: 0
     */
    function Calculator(units, maxVal) {
        if (maxVal === void 0) { maxVal = 0; }
        this.units = units;
        this.maxVal = maxVal;
        this.scrUnits = units == Units.SI ? 'µmol/L' : 'mg/dL';
        this.resultUnits = 'mL/min/1.73 m²';
    }
    /**
     * Validates that the GFR result is within bounds and returns the result to display
     * @param {number} result The GFR result value to validate
     * @returns {string} A string representing the GFR calculation result
     */
    Calculator.prototype.validate = function (result) {
        // Set result to 0 if infinity or NaN
        result = isFinite(result) ? result : 0;
        // Return "Above {maxVal}" if result is over {maxVal}, or the numeric value otherwise
        return this.maxVal > 0 && result > this.maxVal
            ? "Above ".concat(this.maxVal)
            : result.toString();
    };
    return Calculator;
}());
exports.Calculator = Calculator;
var AdultCalculator = /** @class */ (function (_super) {
    __extends(AdultCalculator, _super);
    /**
     * Constructs a new glomerular filtration rate (GFR) calculator for adults age 18 or older
     * @class Gfr.AdultCalculator
     * @classdesc Glomerular filtration rate (GFR) calculator for adults age 18 or older
     * @param {Gfr.Units} units The system used for units of measure
     * @param {Gfr.Type} type The type of calculation to perform (i.e., the equation to use)
     * @param {number} maxVal The maximum value for the result; set to <= 0 to disable maximum value; default: 0
     */
    function AdultCalculator(units, type, maxVal) {
        if (maxVal === void 0) { maxVal = 0; }
        var _this = _super.call(this, units, maxVal) || this;
        _this.type = type;
        _this.maxVal = maxVal;
        return _this;
    }
    /**
     * Calculates the glomerular filtration rate (GFR) for an adult age 18 or older with the specified
     * serum creatinine (Scr) level, age, race, and gender
     * @param {number} scr The serum creatinine (Scr) level in the units indicated by the scrUnits property
     * @param {number} age The age in years (method returns "N/A" if less than 18)
     * @param {boolean} isBlack True if the person is black/African American, or false otherwise
     * @param {boolean} isFemale True if the person is female, or false if the person is male
     * @returns {string} A string representing the GFR calculation result for display
     */
    AdultCalculator.prototype.calculate = function (scr, age, isBlack, isFemale) {
        if (age < 18) {
            return 'N/A';
        }
        var result;
        var isSi = this.units == Units.SI;
        if (this.type == Type.Mdrd) {
            // MDRD for adults
            var race = isBlack ? 1.212 : 1;
            var gender = isFemale ? 0.742 : 1;
            scr = isSi ? scr / 88.4 : scr;
            result = Math.round(175 *
                Math.pow(scr, -1.154) *
                Math.pow(age, -0.203) *
                race *
                gender);
        }
        else if (this.type == Type.CkdEpi) {
            // CKD-EPI for adults
            var race = isBlack ? 1.159 : 1;
            var gender = isFemale ? 1.018 : 1;
            var alpha = isFemale ? -0.329 : -0.411;
            var k = void 0;
            if (isSi) {
                // SI units
                k = isFemale ? 61.9 : 79.6;
            }
            else {
                // conventional units
                k = isFemale ? 0.7 : 0.9;
            }
            var resultMin = Math.pow(Math.min(scr / k, 1), alpha);
            var resultMax = Math.pow(Math.max(scr / k, 1), -1.209);
            result = Math.round(141 *
                resultMin *
                resultMax *
                Math.pow(0.993, age) *
                race *
                gender);
        }
        else {
            return 'Invalid calculator type';
        }
        return _super.prototype.validate.call(this, result);
    };
    /**
     * The maximum result value recommended for reporting GFR values for adults
     */
    AdultCalculator.recommendedMaxVal = 60;
    return AdultCalculator;
}(Calculator));
exports.AdultCalculator = AdultCalculator;
var ChildCalculator = /** @class */ (function (_super) {
    __extends(ChildCalculator, _super);
    /**
     * Constructs a new glomerular filtration rate (GFR) calculator for children under age 18
     * @class Gfr.ChildCalculator
     * @classdesc Glomerular filtration rate (GFR) calculator for children under age 18
     * @param {Gfr.Units} units The system used for units of measure
     * @param {number} maxVal The maximum value for the result; set to <= 0 to disable maximum value; default: 0
     */
    function ChildCalculator(units, maxVal) {
        if (maxVal === void 0) { maxVal = 0; }
        var _this = _super.call(this, units, maxVal) || this;
        _this.maxVal = maxVal;
        return _this;
    }
    /**
     * Calculates the glomerular filtration rate (GFR) for a child with the specified
     * serum creatinine (Scr) level and height
     * @param {number} scr The serum creatinine (Scr) level in the units indicated by the scrUnits property
     * @param {number} height The child's height in centimeters (cm)
     * @returns {string} A string representing the GFR calculation result for display
     */
    ChildCalculator.prototype.calculate = function (scr, height) {
        var isSi = this.units == Units.SI;
        var k = isSi ? 36.2 : 0.41;
        var result = Math.round((k * height) / scr);
        return _super.prototype.validate.call(this, result);
    };
    /**
     * The unit of measure for height values
     */
    ChildCalculator.heightUnits = 'cm';
    /**
     * The maximum result value recommended for reporting GFR values for children
     */
    ChildCalculator.recommendedMaxVal = 75;
    return ChildCalculator;
}(Calculator));
exports.ChildCalculator = ChildCalculator;
