(function ($, win) {
    'use strict';

    var dk = win.dk || {};

    function openRiPopup(url, alt, width, height, title, description, video) {
        var $content,
            $expand,
            $desc,
            titleId = dk.generateId('ri-'),
            descId = dk.generateId('ri-');


        if (video) {
            $content = $(video).attr({
                'aria-labelledby': titleId,
                'aria-describedby': descId
            });
        } else {
            $content = $('<img/>').attr({
                src: url,
                alt: alt,
                width: width,
                height: height,
                'aria-labelledby': titleId,
                'aria-describedby': descId
            });

            $expand = $('<a class="ri-expand" target="_blank"/>').attr('href', url).text('View full-sized image');

            // Add Akamai Image Manager bypass to view full-sized image link
            const a = $expand[0];
            a.search += (a.search ? '&' : '?') + 'imbypass=true';
        }

        $desc = $('<div class="ri-popup-description"/>').attr('id', descId).append(description);

        try { dk.evaluateExitLinks($desc); } catch (_) {
            // ignore
        }

        return $('<div class="ri-popup"/>').append(
            $('<div class="ri-popup-image"/>').append(
                $content,
                $expand
            ),
            $('<div class="ri-popup-information"/>').append(
                $('<div class="ri-popup-title"/>').attr('id', titleId).append(title),
                $desc
            )
        );
    }

    $(function () {
        $('.ri-gallery').on('keypress click', '.ri-card', function (e) {
            var $card = $(this),
                $img = $card.find('img'),
                $desc = $card.find('.ri-description'),
                $vid = $card.find('.ri-video'),
                imgUrl = $img.attr('src'),
                imgAlt = $img.attr('alt'),
                imgWidth = $img.attr('width'),
                imgHeight = $img.attr('height'),
                imgTitle = $card.find('.ri-title').html(),
                imgDesc = $desc.data('content') || $desc.html(),
                videoFrame = $vid.data('content') || $vid.html();

            if (e.which === 13 || e.type === 'click') {
                var $gallery = $card.parents('.ri-gallery:first');
                if ($gallery.is('[data-navigate-link]')) {
                    var link = $('a[href]:first', $card).prop('href');
                    if (link) {
                        location.href = link;
                    }

                } else {
                    $.magnificPopup.open({
                        items: {
                            src: openRiPopup(imgUrl, imgAlt, imgWidth, imgHeight, imgTitle, imgDesc, videoFrame)
                        },
                        type: 'inline',
                        midClick: true,
                        callbacks: {
                            open: function () {
                                $(this.contentContainer).addClass('ri-popup-container');
                            }
                        }
                    });
                }
            }
        });
    });
})(window.jQuery, window);
