(function ($) {
    'use strict';

    function initializeForm() {
        var $form = $(this),
            $doc = $(document),
            $btnGo = $('.ss-go', $form),
            $txtQuery = $(':text[name=q]', $form),
            $hdnScope = $(':hidden[name=s]', $form),
            $menu = $('ul:first', $form).attr({
                role: 'menu',
                'aria-hidden': true
            }),
            menuId = window.dk.generateId('ss-', $menu, true),
            $menuitems = $('li', $menu).attr('role', 'menuitemradio').removeAttr('aria-checked'),
            $btnOptions = $('.ss-options', $form).attr({
                'aria-haspopup': true,
                'aria-controls': menuId,
            }).removeAttr('aria-owns'),
            attrSel = 'aria-selected';

        function toggleMenu(show, isKeyboard) {
            show = typeof show === 'boolean' ? show : !$menu.hasClass('shown');
            $menu[show ? 'addClass' : 'removeClass']('shown');
            if (show) {
                $btnOptions.attr('aria-expanded', true);
                $menu.removeAttr('aria-hidden');
                if (isKeyboard) {
                    $menuitems.filter(':first').attr(attrSel, true);
                } else {
                    $doc.on('click.closeSearchMenu', function () {
                        toggleMenu(false);
                        $doc.off('click.closeSearchMenu');
                    });
                }
            } else {
                $btnOptions.removeAttr('aria-expanded');
                $menu.attr('aria-hidden', true);
                $menuitems.removeAttr(attrSel);
            }
        }

        function updateScope($li) {
            var text = 'Search ' + $li.text();
            $li.attr('aria-checked', 'true')
                .siblings().removeAttr('aria-checked');
            $hdnScope.val($li.attr('data-value'));
            $txtQuery.attr('placeholder', text + '…');
            $btnGo.attr('title', text);
        }

        $menuitems.first().attr('aria-checked', true);

        $txtQuery.on('focus', function() {
            toggleMenu(false);
        });

        $btnOptions.on('click', function (e) {
            toggleMenu();
            e.preventDefault();
            return false;
        }).on('blur', function () {
            toggleMenu(false);
        });

        $doc.on('keydown', function (e) {
            var w = e.which,
                $active = $(document.activeElement),
                $target, $first, $last, $sel;
            // 13: enter, 27: escape, 32: space, 38: up, 40: down
            if ($active.is($btnOptions)) {
                if ($.inArray(w, [13, 27, 32, 38, 40]) > -1) {
                    e.preventDefault();
                    if ($menu.hasClass('shown')) {
                        $first = $menuitems.filter(':first');
                        $last = $menuitems.filter(':last');
                        $sel = $menuitems.filter('[' + attrSel + ']:first');
                        switch (w) {
                            case 13:
                            case 32:
                                $sel.trigger('click');
                                break;
                            case 27:
                                toggleMenu(false);
                                $btnOptions.trigger('focus');
                                break;
                            case 38:
                            case 40:
                                if (w === 38 && $sel.is($first)) {
                                    $target = $last;
                                } else if (w === 40 && $sel.is($last)) {
                                    $target = $first;
                                } else {
                                    $target = $sel[w === 38 ? 'prev' : 'next']();
                                }
                                if ($target.length < 1) {
                                    $target = $first;
                                }
                                $target.attr(attrSel, true).siblings()
                                    .removeAttr(attrSel);
                                break;
                        }
                    } else {
                        toggleMenu(w === 40 || (w === 27 ? false : ''), true);
                    }
                    return false;
                }
            } else if (w === 13 && $active.is($txtQuery)) {
                e.preventDefault();
                $form.submit();
                return false;
            }
        });

        $menuitems.on('click', function (e) {
            e.preventDefault();
            updateScope($(this));
            $txtQuery.trigger('focus');
            toggleMenu(false);
            return false;
        }).on('mouseover', function () {
            $(this).attr(attrSel, true).siblings().removeAttr(attrSel);
        });

        $form.on('submit', function (e) {
            var val = ($txtQuery.val() || '').replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' ');
            if (val.length > 0) {
                $txtQuery.val(val);
                return true;
            } else {
                e.preventDefault();
                window.alert('Please enter keywords before searching.');
                $txtQuery.trigger('focus');
                if ($txtQuery[0]) {
                    $txtQuery[0].select();
                }
                return false;
            }
        });

        updateScope($menu.find('li:first'));
        toggleMenu(false);
    }

    function initializeMobileSearch() {
        var getHandler,
            $siteWrapper = $('.site-wrap'),
            $siteBanner = $siteWrapper.find('.dk-brand-wrap'),
            $closeMobileSearchButton = $siteBanner.find('.close-mobile-site-search'),
            $siteSearch = $siteBanner.find('.dk-site-search'),
            $mobileSearchButton = $siteBanner.find('button.mobile-site-search');

        function setMobileSearch(show) {
            var method = show ? 'addClass' : 'removeClass';

            $siteWrapper[method]('overlay search-overlay');
            $closeMobileSearchButton[method]('show');
            $siteSearch[method]('show');

            // Fix bouncing sticky nav
            if (show) {
                $('.site-header').removeClass('offscreen sticky').addClass('nst');
            } else {
                window.setTimeout(function () {
                    $('.site-header').removeClass('nst');
                }, 50);
            }

            window.dk.setBodyScrollLock(show);
            $('.page-mask')[show ? 'on' : 'off']('click', getHandler(false));
        }

        getHandler = function(show) {
            return function (e) {
                e.preventDefault();
                setMobileSearch(show);
                return false;
            };
        };

        $mobileSearchButton.on('click', getHandler(true));
        $closeMobileSearchButton.on('click', getHandler(false));
    }

    // Document ready
    $(function () {
        $('.dk-site-search').each(initializeForm);
        initializeMobileSearch();
    });
})(window.jQuery);
