(function ($) {
    'use strict';

    function printContent(content) {
        var params = [
            'width=' + (screen.width - 50),
            'height=' + (screen.height - 200),
            'top=0,left=0'
        ].join(','),
            popupWin = window.open('', '_blank', params),
            doc = popupWin.document;

        content = content.replace(/<body/, '<body onload="window.print();window.close();"');

        doc.open();
        doc.write(content);
        doc.close();
    }

    function parseAjax(mfpResponse) {
        var data = mfpResponse.data,
            $box = $(data).find('.lightbox:first'),
            $printLink = $('<a href="javascript:" class="print" />').on('click', function (e) {
                e.preventDefault();
                printContent(data);
                return false;
            }).text('Print');
        $box.append($printLink);
        mfpResponse.data = $box;
    }

    // Document load
    $(function () {
        $('a.open-popup[href],.open-popup[data-href]').each(function () {
            var $me = $(this),
                href = $me.attr('href') || $me.data('href'),
                $elt, ctrClass, opts;
            if (href && href.match(/^#/) && ($elt = $(href)).length) {
                opts = {
                    type: 'inline',
                    midClick: true
                };
                ctrClass = $elt.data('class');
                if (ctrClass) {
                    opts.callbacks = {
                        open: function () {
                            $(this.contentContainer).addClass(ctrClass);
                        }
                    };
                }
                $me.magnificPopup(opts);
            }
        });

        $('.dictionary-term').magnificPopup({
            type: 'ajax',
            callbacks: {
                parseAjax: parseAjax
            }
        });
    });

})(window.jQuery);
