(function (win, $) {
    'use strict';

    var dk = window.dk,
        stickyDefaults = {
            ot: 10, // minimum distance of the top of the panel from the bottom of the blog title bar,
            ob: 15, // minimum distance of the bottom of the panel from the bottom of the content area
            snh: 56 // height of the site-wide sticky navigation (56 = 4rem)
        };

    $(function () {
        $('.pt-blog form.blog-search').on('submit', function (e) {
            var $frm = $(this), $txtQuery = $('input:text', $frm),
                val = ($txtQuery.val() || '').replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' ');
            if (val.length > 0) {
                $txtQuery.val(val);
                return true;
            } else {
                e.preventDefault();
                win.alert('Please enter keywords before searching.');
                $txtQuery.trigger('focus');
                if ($txtQuery[0]) {
                    $txtQuery[0].select();
                }
                return false;
            }
        });

        $('.dk-blog-rail .subscribe').not(':first').remove().end().each(function () {
            var $panel = $(this).css('visibility', 'hidden'),
                $tray = $('.tray', $panel),
                $links = $('input,button', $tray),
                $button = $panel.children(':first'),
                $ctr = $('.dk-main-container:first'),
                $blogTitle = $('.blog-title:first', $ctr),
                $rightRail = $('.dk-blog-rail:first', $ctr),
                $form = $tray.children(':first'),
                buttonTitle = $button.attr('title') || '',
                resetAndUpdate = dk.debounce(function () { resetPanelLocation(true); }, 250, true),
                togglePanel, keydownTo, clickedOpen,
                ctrMinTop, ctrMinBottom, initialTop, fixedTop,
                clickable;

            function resetPanelLocation(updateVals) {
                var ctrBox = $ctr[0].getBoundingClientRect(),
                    railBox = $rightRail[0].getBoundingClientRect(),
                    ctrPadRight = parseInt($ctr.css('padding-right'), 10),
                    panelBox, titleBox, isAtTop = true, isBelowRail;

                function val(v) {
                    return dk.responsive.isTablet ? dk.toTabletPx(v) : v;
                }

                if (updateVals) {
                    titleBox = $blogTitle[0].getBoundingClientRect();
                    initialTop = titleBox.height + val(stickyDefaults.ot);
                    fixedTop = val(stickyDefaults.snh) + val(stickyDefaults.ot);
                    ctrMinTop = val(stickyDefaults.snh) - titleBox.height;
                    ctrMinBottom = fixedTop + val(stickyDefaults.ob);
                    $form.css('width', dk.responsive.isMobile ? '' : $rightRail.width() + ctrPadRight + 3);
                }

                $panel.css({ 'position': '', 'top': initialTop, 'left': '', 'right': '' });
                panelBox = $panel[0].getBoundingClientRect();
                if (ctrBox.top <= ctrMinTop && (ctrBox.bottom >= ctrMinBottom + panelBox.height)) {
                    $panel.css({ 'position': 'fixed', 'top': fixedTop, 'left': panelBox.left, 'right': 'auto' });
                    isAtTop = false;
                } else if (ctrBox.bottom < ctrMinBottom + panelBox.height) {
                    $panel.css({ 'position': '', 'top': ctrBox.height - panelBox.height - val(stickyDefaults.ob), 'left': '', 'right': '' });
                    isAtTop = false;
                }

                isBelowRail = !dk.responsive.isMobile && railBox.bottom < fixedTop;
                isAtTop = !dk.responsive.isMobile && isAtTop;
                clickable = !(isAtTop || isBelowRail);

                if (!clickedOpen) {
                    togglePanel(isAtTop || isBelowRail);
                }

                $panel.show();
                $button.css('cursor', clickable ? 'pointer' : 'default');
            }

            const toggleOverlay = (function () {
                let isShown;
                return function (show) {
                    show = show && dk.responsive.isMobile;
                    if (show === isShown) return;
                    isShown = show;
                    const $mask = $('.page-mask'), evt = 'click.closeSubscribe';
                    $('.site-wrap')[show ? 'addClass' : 'removeClass']('subscribe-overlay');
                    dk.setBodyScrollLock(show);
                    $mask.off(evt);
                    if (show) {
                        $mask.on(evt, function () {
                            togglePanel(false);
                            clickedOpen = false;
                        });
                    }
                };
            })();

            togglePanel = (function () {
                let isShown;
                return function (show) {
                    if (show === isShown) return;
                    isShown = show;
                    const title = ($button.attr('title') || buttonTitle).replace(/Hide|Show/, show ? 'Hide' : 'Show');
                    if (show !== $panel.hasClass('opened')) {
                        $panel[show ? 'addClass' : 'removeClass']('opened');
                    }
                    if (clickable && title) {
                        $button.attr('title', title);
                    } else {
                        $button.removeAttr('title');
                    }
                    toggleOverlay(show);
                };
            })();

            $button.on('click', function (e) {
                var show = !$panel.hasClass('opened');
                e.preventDefault();
                if (!clickable) {
                    return false;
                }
                togglePanel(show);
                clickedOpen = false;
                if (show) {
                    $('input:text', $tray).trigger('focus');
                    clickedOpen = true;
                }
                return false;
            });

            $(document).on('keydown.subscribe', function (e) {
                var w = e.which;
                if (w === 9) {
                    clearTimeout(keydownTo);
                    keydownTo = setTimeout(function () {
                        togglePanel($links.filter(document.activeElement).length > 0);
                    }, 50);
                }
            });

            $('form', $panel).on('submit', function (e) {
                var $frm = $(this), $txtQuery = $('input:text', $frm),
                    val = ($txtQuery.val() || '').replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' ');
                if (val.match(/.*@.*\..*/)) {
                    $txtQuery.val(val);
                    return true;
                } else {
                    e.preventDefault();
                    win.alert('Please enter a valid email address to subscribe.');
                    $txtQuery.trigger('focus');
                    if ($txtQuery[0]) {
                        $txtQuery[0].select();
                    }
                    return false;
                }
            });

            const $win = $(win);
            $win.on('dk:widthChanged', (_, args) => { // Handle panel sticky position on scroll
                const evt = 'scroll.subscribe';
                resetAndUpdate();
                $win.off(evt).on(evt, () => resetPanelLocation());
                if (args.layoutChanged) { // Handle panel open state when changing layouts
                    if (args.isMobile) {
                        if (clickedOpen) {
                            clickedOpen = false;
                            togglePanel(false);
                        } else {
                            $panel.removeClass('opened');
                        }
                    } else {
                        toggleOverlay(false);
                    }
                }
                if (args.isForced) {
                    $panel.css('visibility', 'visible');
                }
            });

            win.setTimeout(function () {
                win.dk.refreshSiteLayout();
            }, 500);
        });
    });
})(window, window.jQuery);
