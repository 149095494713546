(function($, win) {
    'use strict';

    var dk = win.dk || {};

    function configureList($elt, level, startCollapse) {
        $elt.children().each(function () {
            var $li = $(this),
                $ul = $li.children('ul:first'),
                ulId, iptId, $ipt;
            if ($ul.length > 0 && level >= startCollapse) {
                $ul.attr('aria-hidden', true);
                ulId = dk.generateId('cl-', $ul, true);
                $ipt = $('<input type="checkbox"/>').attr('aria-controls', ulId).on('change', function() {
                    var $t = $(this), isChecked = $t.prop('checked');
                    $t.attr('aria-expanded', isChecked ? 'true' : null);
                    $ul.attr('aria-hidden', !isChecked);
                });
                iptId = dk.generateId('cl-', $ipt, true);
                $li.prepend(
                    $ipt,
                    $('<label/>').attr('for', iptId).text('Expand section')
                );
            }
            configureList($ul, level + 1, startCollapse);
        });
    }

    $(function () {
        $('.collapse-list:not([data-initialized])').each(function () {
            var $list = $(this).attr('data-initialized', ''),
                startLevel = Math.max(parseInt($list.attr('data-collapse-level') || '1', 10), 1);
            configureList($list, 1, startLevel);
            $list.data('toggle', function (setOpen) {
                $list.find(':checkbox').each(function () {
                    $(this).prop('checked', setOpen ? true : false);
                });
            });
        });
    });
})(window.jQuery, window);
