(function (win, $) {
    'use strict';

    var dk = win.dk,
        // Links matching the following selectors will be excluded
        excludedLinkSelectors = [
            '.email',
            '.no-icon',
            '.no-email-icon',
            '.i-envelope~',
            '.i-envelope-o~'
        ].join(','),
        // Links with parent elements matching the following selectors will be excluded
        excludedParentSelectors = [
            '.no-email-icon',
            '.addthis_toolbox',
            '.recaptchatable',
            '.twitter-tweet',
            '.embed',
            '.result-items.staff .email',
            '.contact-buttons',
            '.email-hours',
            '.branch-header .chief',
            '.dk-share',
            '.social-contact-links'
        ].join(',');

    function isString(str) {
        return typeof str === 'string';
    }

    function startsWith(str, val) {
        return isString(str) && isString(val) && str.slice(0, val.length) === val;
    }

    function evaluateEmailLinks(parent) {
        if (dk.isEditing) return;

        // Identify links to process the handle each of them
        $(parent || 'main').find('a[href]').filter(function () {
            var $me = $(this), proto = $me.prop('protocol');
            return startsWith(proto, 'mailto') &&
                !$me.is(excludedLinkSelectors) &&
                !$me.parents().is(excludedParentSelectors);
        }).each(function () {
            $(this).addClass('email');
        });
    }

    dk.evaluateEmailLinks = evaluateEmailLinks;

    // Document ready
    if (!dk.isEditing) $(function () { evaluateEmailLinks(); });
})(window, window.jQuery);
